<template>
  <StandardPageLayout>
    <p class="bg-white p-5 text-sm rounded shadow">⚠️ <strong>Note:</strong> If you log in to Follow Up Boss using a <strong>Google</strong> or <strong>Microsoft</strong> account, our team will not be able to access your account. To enable access, you will first need to contact <MailToLink emailAddress="support@followupboss.com" /> requesting that your login be set to username/password.</p>
    <p class="mt-5 text-xl font-medium">Have you updated your Follow Up Boss credentials?</p>
    <p class="mt-5">Submit your updated credentials using the form below so our team can access your account.</p>
    <form class="mt-5" @submit.prevent="submit">
      <p>Follow Up Boss Credentials (Owner Account)</p>
      <p class="text-sm">Note that we securely store passwords using encryption against a private business key.</p>
      <input type="text" v-model="username" class="text-input" placeholder="Username">
      <br>
      <input type="text" v-model="password" class="text-input" placeholder="password">
      <br>
      <button type="submit" class="submit-button" :disabled="loading">Submit</button>
      <p class="text-red-500 mt-2 text-sm font-medium">{{ error }}</p>
    </form>
    <Modal v-if="success" class="text-center">
      <p class="mb-3">Your Follow Up Boss credetials were successfully submitted.</p>
      <router-link to="/" replace><button>Done</button></router-link>
    </Modal>
  </StandardPageLayout>
</template>
<script>

export default {
  props: ['keeId'],
  data() {
    return {
      loading: false,
      error: "",
      success: false,
      username: "",
      password: ""
    }
  },
  methods: {
    async submit() {
      try {
        this.error = ""
        this.loading = true
        await this.$service.creds({
          keeId: this.keeId,
          fubUsername: this.username,
          fubPassword: this.password
        })
        this.success = true
      } catch (e) {
        this.loading = false
        this.error = 'Uh oh. Looks like there was an issue submitting the form. Please try again at a later time.'
      }
    }
  }
}
</script>
<style scoped>
input[type=text] {
  @apply mt-3 border border-gray-300 py-2 px-3 rounded w-72 text-sm;
}
button {
  @apply bg-kts-gold py-2 px-5 mt-3 font-medium rounded;
}
</style>