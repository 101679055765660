<template>
  <div>
    <div id="card-container"></div>
  </div>
</template>
<script>
let card;
export default {
  async mounted() {
    if (!window.Square) {
      throw new Error("Square.js failed to load properly");
    }
    let payments;
    try {
      payments = window.Square.payments(this.appId, this.locationId);
    } catch {
      // to-do
      return;
    }

    try {
      card = await payments.card();
      await card.attach("#card-container");
    } catch (e) {
      console.error("Initializing Card failed", e);
      return;
    }
  },
  computed: {
    appId() {
      return this.$isProduction
        ? "sq0idp-Sr4u_TGzJh1WhVMkYQaacA"
        : "sandbox-sq0idb-2dBEFwXkfUQCvKy8p07I9A";
    },
    locationId() {
      return this.$isProduction ? "LV60D0N6MK0DF" : "LHZ0V0E0N4CRV";
    },
  },
  methods: {
    async tokenize() {
      // TO-DO - Verify Buyer - https://developer.squareup.com/reference/sdks/web/payments/objects/Payments#Payments.verifyBuyer
      // This is for Strong Customer Authentication - https://squareup.com/help/gb/en/article/7373-strong-customer-authentication-faq
      const tokenResult = await card.tokenize();
      if (tokenResult.status === "OK") {
        return {
          locationId: this.locationId,
          sourceId: tokenResult.token,
        }
      } else {
        let errorMessage = `Tokenization failed with status: ${tokenResult.status}`;
        if (tokenResult.errors) {
          errorMessage += ` and errors: ${JSON.stringify(tokenResult.errors)}`;
        }

        throw new Error(errorMessage);
      }
    },
  },
};
</script>