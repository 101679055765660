import wretch from "wretch";

export const rootUrl = process.env.NODE_ENV === "production" ? 'https://api.keetechnology.com' : 'http://localhost:3000'

export const getErrorMessage = ({ status, json = {} }) => {
  switch(status) {
    case 422:
      console.error('Joi Schema Validation Error: ', json.message)
      return 'Not a valid request payload.'
  }

  return json.message || 'Something went wrong.'
}

export const api = wretch().resolve(resolver => resolver.json()).errorType("json")
  
export const createSignup = (signUp) => api.url(`${rootUrl}/fub/sign-up`).post(signUp)

export const manualSignUp = (signUp) => api.url(`${rootUrl}/fub/manual-signup`).post(signUp)

export const manualSetupData = (payload) => api.url(`${rootUrl}/fub/manual-setup-data`).post(payload)

export const createSetup = (setup) => api.url(`${rootUrl}/fub/setup-notes`).post(setup)

export const payBalance = (balance) => api.url(`${rootUrl}/fub/balance`).post(balance)

export const getPerson = (keeId) => api.url(`${rootUrl}/fub/person/${keeId}`).get()

export const encrypt = (payload) => api.url(`${rootUrl}/crypto/encrypt`).post(payload)

export const decrypt = (payload) => api.url(`${rootUrl}/crypto/decrypt`).post(payload)

export const redeemGuide = (payload) => api.url(`${rootUrl}/guide/redeem`).post(payload)

export const biWeeklyCallSignUp = (payload) => api.url(`${rootUrl}/fub/bi-weekly-call-sign-up`).post(payload)

export const getGroupTraining = (keeId, setupType) => api.url(`${rootUrl}/group-training/library/${keeId}`).query({ setupType }).get()

export const extendGroupTrainingAccess = (payload) => api.url(`${rootUrl}/group-training/extension`).post(payload)

export const getAffiliateSlugs = () => api.url(`${rootUrl}/affiliate/slugs`).get()

export const getAffiliateBySlug = (slug) => api.url(`${rootUrl}/affiliate/slugs/${slug}`).get()

export const getAffiliatePayoutNotes = (keeId) => api.url(`${rootUrl}/affiliate/payout-notes/${keeId}`).get()

export const postConversionly = (payload) => api.url(`${rootUrl}/fub/conversionly`).post(payload)

export const postAuditInquiry = (payload) => api.url(`${rootUrl}/fub/audit-inquiry`).post(payload)

export const postPromo = (payload) => api.url(`${rootUrl}/fub/promo`).post(payload)

export const creds = (payload) => api.url(`${rootUrl}/fub/creds`).post(payload)

export const bossAssistSignup = (payload) => api.url(`${rootUrl}/fub/boss-assist-signup`).post(payload)

export const cancelSubscription = (id) => api.url(`${rootUrl}/square/subscription/${id}/cancel`).post()
