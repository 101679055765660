<template>
  <StandardPageLayout>
    <p class="text-2xl font-semibold text-site-purple">Thanks for signing up!</p>
    <p class="text-lg mt-5">
      We will need the following information before we can start revamping your
      FUB.
    </p>
    <p class="font-medium text-sm mt-2">PLEASE NOTE: We do not provide Spanish translations for our lender revamp at this time.</p>
    <!-- Basic Info -->
    <section v-if="!keeId">
      <p class="font-semibold text-lg">Your Info</p>
      <div class="inline-block bg-gray-100 p-5 rounded pt-3">
        <VTextField :v="v$.formModel.basicInfo.firstName" placeholder="First Name"/>
        <VTextField :v="v$.formModel.basicInfo.lastName" placeholder="Last Name"/>
        <VTextField :v="v$.formModel.basicInfo.email" placeholder="Email"/>
        <VTextField :v="v$.formModel.basicInfo.phone" placeholder="Phone Number"/>
      </div>
    </section>
    <section>
      <p class="font-semibold text-lg">Who will the setup be enabled for?</p>
      <div class="field" :class="{'field-block-error': v$.formModel.setupEnabledFor.$error}">
        <p v-if="v$.formModel.setupEnabledFor.$error" class="field-error text-xs text-red-500">
          {{ v$.formModel.setupEnabledFor.$errors[0].$message }}
        </p>
        <input type="radio" value="All users" v-model="formModel.setupEnabledFor" />
        <label>All users within the FUB account <span class="text-gray-600 text-sm">(This option is the default behavior of our setup and is recommended for most teams and solo agents.)</span></label>
        <br />
        <input type="radio" value="Only me or a limited set of users" v-model="formModel.setupEnabledFor" />
        <label>Only me OR a limited set of users in the FUB account <span class="text-gray-600 text-sm">(This option is a customization to our default setup and is an additional ${{ customUserEnablingPricing }} for the custom work. Any changes to this after the setup is complete will be billed as hourly work.)</span></label>
      </div>
    </section>
    <!-- Follow Up Boss Credentials -->
    <section>
      <p class="font-semibold text-lg">
        Follow Up Boss Credentials
        <span class="text-base font-light text-gray-700">(Owner Account)</span>
      </p>
      <div class="inline-block bg-gray-100 p-5 rounded pt-3">
        <VTextField :v="v$.formModel.fubUsername" placeholder="Username" autocomplete="off" />
        <VTextField :v="v$.formModel.fubPassword" placeholder="Password" autocomplete="off" />
      </div>
    </section>
    <!-- Website URL(s) -->
    <section class="inline-block">
      <p class="font-semibold text-lg -mb-2">Website URL</p>
      <div class="field bg-gray-100 p-5" v-for="(website, idx) in formModel.websites" :key="`website_${idx}`"
        :class="{ 'mt-5': idx !== 0 }">
        <input type="text" v-model="website.url" placeholder="http://mywebsite.com" />
        <span v-if="idx !== 0" class="cursor-pointer ml-2" @click="removeWebsite(idx)">
          <font-awesome-icon icon="fa-solid fa-times" />
        </span>
      </div>
      <p class="mt-5 font-semibold text-sm">Have multiple websites?</p>
      <a @click="addWebsite" class="
        text-site-purple
        font-semibold
        mt-1
        inline-block
        underline
        cursor-pointer
      ">
        <font-awesome-icon icon="fa-solid fa-plus-circle" />
        Add Website
      </a>
    </section>
    <!-- Online Reviews -->
    <section>
      <p class="font-semibold text-lg">Online Reviews <span class="text-base font-light text-gray-700">(Please provide
          any URLs to your online reviews i.e Google, Facebook etc.)</span></p>
      <!-- <p class="text-base font-light text-gray-700">This is so you can ask for reviews in the post closing drips and build trust with potential clients in the nuture drips</p> -->
      <div class="inline-block">
        <div class="field bg-gray-100 p-5" v-for="(onlineReview, idx) in formModel.onlineReviews"
          :key="`online_review_${idx}`" :class="{ 'mt-5': idx !== 0 }">
          <input type="text" v-model="onlineReview.url" placeholder="http://review-url.com" />
          <span v-if="idx !== 0" class="cursor-pointer ml-2" @click="removeOnlineReview(idx)">
            <font-awesome-icon icon="fa-solid fa-times" />
          </span>
        </div>
      </div>
      <p class="mt-5 font-semibold text-sm">Have more online review URLs?</p>
      <a @click="addOnlineReview" class="
        text-site-purple
        font-semibold
        mt-1
        inline-block
        underline
        cursor-pointer
      ">
        <font-awesome-icon icon="fa-solid fa-plus-circle" />
        Add Online Review URL
      </a>
    </section>
    <!-- YouTube Channel -->
    <section>
      <p class="font-semibold text-lg">YouTube Channel</p>
      <div class="inline-block bg-gray-100 p-5 rounded pt-3">
        <div class="field">
          <VTextField :v="v$.formModel.youtube" placeholder="YouTube Channel URL" />
        </div>
      </div>
    </section>
    <!-- Company Name -->
    <section>
      <p class="font-semibold text-lg">Company Name</p>
      <div class="inline-block bg-gray-100 p-5 rounded pt-3">
        <VTextField :v="v$.formModel.companyName" placeholder="Company Name" />
      </div>
    </section>
    <!-- Business Address -->
    <section>
      <p class="font-semibold text-lg">Business Address</p>
      <div class="inline-block bg-gray-100 p-5 rounded pt-3">
        <VTextField :v="v$.formModel.businessAddress.addressLine1" placeholder="Address Line 1" />
        <VTextField :v="v$.formModel.businessAddress.addressLine2" placeholder="Address Line 2" />
        <CountrySelect
          v-model="formModel.businessAddress.country"
          :errorMessage="v$.formModel.businessAddress.country.$error ? v$.formModel.businessAddress.country.$errors[0].$message : ''"
        />
        <VTextField :v="v$.formModel.businessAddress.city" placeholder="City" />
        <StateSelect
          v-model="formModel.businessAddress.state"
          :errorMessage="v$.formModel.businessAddress.state.$error ? v$.formModel.businessAddress.state.$errors[0].$message : ''"
          :showProvinces="formModel.businessAddress.country === 'Canada'"
        />
        <VTextField :v="v$.formModel.businessAddress.zip" :placeholder="formModel.businessAddress.country === 'Canada' ? 'Postal Code' : 'Zip Code'" />
      </div>
    </section>
    <!-- Servicing Multiple Markets -->
    <section>
      <p class="font-semibold text-lg">Do you service more than one market (multiple states/provinces, regions)?</p>
      <div class="field" :class="{
        'field-block-error': v$.formModel.multiMarketServicing.$error,
      }">
        <p v-if="v$.formModel.multiMarketServicing.$error" class="field-error text-xs text-red-500">
          {{ v$.formModel.multiMarketServicing.$errors[0].$message }}
        </p>
        <input type="radio" value="Yes" v-model="formModel.multiMarketServicing" />
        <label>Yes</label>
        <br />
        <input type="radio" value="No" v-model="formModel.multiMarketServicing" />
        <label>No</label>
      </div>
    </section>
    <!-- Pixel -->
    <section>
      <p class="font-semibold text-lg">
        Is the Follow Up Boss Pixel installed on your website?
      </p>
      <div class="field" :class="{ 'field-block-error': v$.formModel.fubPixelInstalled.$error }">
        <p v-if="v$.formModel.fubPixelInstalled.$error" class="field-error text-xs text-red-500">
          {{ v$.formModel.fubPixelInstalled.$errors[0].$message }}
        </p>
        <input type="radio" id="fub-pixel-installed-yes" value="Yes" v-model="formModel.fubPixelInstalled" />
        <label for="fub-pixel-installed-yes">Yes</label>
        <br />
        <input type="radio" id="fub-pixel-installed-no" value="No" v-model="formModel.fubPixelInstalled" />
        <label for="fub-pixel-installed-no">No</label>
        <br />
        <input type="radio" id="fub-pixel-installed-no-not-compatible" value="Not Compatible"
          v-model="formModel.fubPixelInstalled" />
        <label for="fub-pixel-installed-no-not-compatible">No and I know my website isn't compatible with the FUB
          Pixel</label>
      </div>
    </section>
    <section v-if="formModel.fubPixelInstalled === 'No'" class="inline-block">
      <p class="font-semibold text-lg">Courtesy service for installing the Follow Up Boss Pixel</p>
      <p class="text-sm text-gray-700">If you have a website backend and would like us to attempt the installation of the Follow Up Boss pixel for you, please provide your site's credentials below.<br><br><strong>Please note</strong> we offer this install service as a courtesy only and is not an objective step in the FUB revamp service we provide.<br><br><strong>Please note</strong> not every website is compatible with the Follow Up Boss Pixel and some site providers even reject the install of 3rd party scripts. Often we don't know compatibility issues until we attempt to install.<br><br>If you prefer to install the FUB pixel yourself you can refer to <a class="link" href="https://help.followupboss.com/hc/en-us/sections/1500001949321-Follow-Up-Boss-Pixel" target="_blank">Follow Up Boss Pixel documentation</a></p>
      <div v-for="(websiteBackend, idx) in formModel.websiteBackends" :key="`website_backend_${idx}`"
        class="bg-gray-100 p-5 pt-3 mt-5">
        <div v-if="idx !== 0" class="flex justify-end">
          <span class="cursor-pointer" @click="removeBackend(idx)">
            <font-awesome-icon icon="fa-solid fa-times" /> Remove
          </span>
        </div>
        <div class="field">
          <input type="text" v-model="websiteBackend.url" placeholder="Backend URL" />
        </div>
        <div class="field">
          <input type="text" v-model="websiteBackend.username" placeholder="Username" />
        </div>
        <div class="field">
          <input type="text" v-model="websiteBackend.password" placeholder="Password" />
        </div>
      </div>
      <p class="mt-5 font-semibold text-sm">
        Need the FUB pixel added to multiple websites?
      </p>
      <a @click="addBackend" class="
        text-site-purple
        font-semibold
        mt-1
        inline-block
        underline
        cursor-pointer
      ">
        <font-awesome-icon icon="fa-solid fa-plus-circle" />
        Add Website
      </a>
    </section>
    <!-- Stage setup -->
    <section>
      <p class="font-semibold text-lg">Setting up stages for the revamp<br /><span class="link underline text-base font-normal" @click="showStagesModal = true"><font-awesome-icon icon="fa-solid fa-info-circle" /> see stage definitions</span></p>
      <div class="field" :class="{ 'field-block-error': v$.formModel.stageSetup.$error }">
        <p v-if="v$.formModel.stageSetup.$error" class="field-error text-xs text-red-500">
          {{ v$.formModel.stageSetup.$errors[0].$message }}
        </p>
        <input type="radio" :value="stageSetupTypes.DEFAULT" v-model="formModel.stageSetup" />
        <label>Use the default stages provided by the revamp (recommended for most)</label>
        <br />
        <input type="radio" :value="stageSetupTypes.CUSTOM" v-model="formModel.stageSetup" />
        <label>I would like to opt out of using the default stages used by the revamp. Instead, I have a requirement that the revamp be customized to use predefined stages that I will provide to Kee Technology Solutions. I acknowledge that I must provide names of my stages before work on the revamp can be started and <strong>I agree to pay a ${{ customStagesPricing }} customization fee.</strong> I acknowledge that my review is required before going live with the revamp. (Note: We will reach out to you after your submission to obtain a list of stages we can use. We will reach back out after the setup is complete to review the setup with you before we go live with it.)</label>
      </div>
      <StagesModal v-if="showStagesModal" @close="showStagesModal = false" />
    </section>
    <!-- SMS Add On -->
    <section>
      <p class="font-semibold text-lg">SMS Drips <span class="text-base font-normal">(Additional ${{ smsDripsPricing }})</span></p>
      <p class="mt-2">If you currently use either <strong>Texting Betty</strong>, <strong>Leadngage</strong>, or <strong>StreetText</strong> for automated texting in FUB, we can add our SMS Drips to the setup we provide for an additional ${{ smsDripsPricing }}. If you'd like our SMS Drips provided in our setup, please check the box below.</p>
      <p class="mt-2 bg-blue-900 text-white text-sm py-2 px-5 inline-block">Note: You can always sign up for this add-on at a later time if, in the future, you sign up for either Texting Betty, Leadngage, or StreetText.</p>
      <div class="field">
        <input type="checkbox" v-model="formModel.includeSMSDrips" true-value="Yes" false-value="No" />
        <label class="ml-2"><span class="font-medium">Include SMS Drips as an add-on. (Additional ${{ smsDripsPricing }})</span> <span class="text-sm">By checking this box you agree that you currently are using either <strong>Texting Betty</strong>, <strong>Leadngage</strong>, or <strong>StreetText</strong>.</span></label>
      </div>
    </section>
    <section v-if="includeSMSDrips">
      <p class="font-semibold text-lg">Texting automation service you use</p>
      <div class="field" :class="{ 'field-block-error': v$.formModel.textingService.$error }">
        <p v-if="v$.formModel.textingService.$error" class="field-error text-xs text-red-500">
          {{ v$.formModel.textingService.$errors[0].$message }}
        </p>
        <input type="radio" value="Texting Betty" v-model="formModel.textingService" />
        <label>Texting Betty</label>
        <br />
        <input type="radio" value="Leadngage" v-model="formModel.textingService" />
        <label>Leadngage</label>
        <br />
        <input type="radio" value="StreetText" v-model="formModel.textingService" />
        <label>StreetText</label>
      </div>
    </section>
    <!-- Setup activation notice -->
    <div class="p-10 mt-10 border bg-gray-100">
      <h3 class="text-lg font-semibold">Activating your new setup</h3>
      <p class="mt-5">After the setup of your new action plans and automations is complete, we will notify you via email. At this stage, you can either approve immediate activation or opt for a review period to evaluate the setup before it goes live.</p>
    </div>
    <!-- Project Price -->
    <section class="inline-block border p-10 bg-gray-100 text-gray-700">
      <p class="text-xl font-medium">Project Price</p>
      <p class="text-lg font-medium mt-2">{{ totalDollarAmount }}</p>
      <p class="mt-2"><span class="link underline text-base font-normal" @click="showPriceBreakdownModal = true"><font-awesome-icon icon="fa-solid fa-info-circle" /> see price breakdown</span></p>
    </section>
    <!-- Payment -->
    <section>
      <div class="mt-5">
        <p class="text-2xl font-semibold">
          Payment
        </p>
        <p class="text-lg font-semibold mt-5">
          Amount: {{ totalDollarAmount }} <span class="text-sm font-normal">(USD)</span>
        </p>
      </div>
      <div class="inline-block bg-gray-100 p-5 rounded pt-3 mt-5">
        <p class="font-semibold">Billing Information</p>
        <VTextField :v="v$.formModel.billing.firstName" placeholder="First Name" />
        <VTextField :v="v$.formModel.billing.lastName" placeholder="Last Name" />
        <VTextField :v="v$.formModel.billing.addressLine1" placeholder="Address Line 1" />
        <VTextField :v="v$.formModel.billing.addressLine2" placeholder="Address Line 2" />
        <CountrySelect
          v-model="formModel.billing.country"
          :errorMessage="v$.formModel.billing.country.$error ? v$.formModel.billing.country.$errors[0].$message : ''"
        />
        <VTextField :v="v$.formModel.billing.city" placeholder="City" />
        <StateSelect
          v-model="formModel.billing.state"
          :errorMessage="v$.formModel.billing.state.$error ? v$.formModel.billing.state.$errors[0].$message : ''"
          :showProvinces="formModel.billing.country === 'Canada'"
        />
        <VTextField :v="v$.formModel.billing.zip" :placeholder="formModel.billing.country === 'Canada' ? 'Postal Code' : 'Zip Code'" />
        <VTextField :v="v$.formModel.billing.phone" placeholder="Phone" />
        <p class="mt-5 font-semibold">Card Information</p>
        <SquarePayment ref="squarePayment" />
      </div>
    </section>
    <section>
      <div class="field" :class="{ 'field-block-error': v$.formModel.agreeToConditions.$error }">
        <p v-if="v$.formModel.agreeToConditions.$error" class="field-error text-xs text-red-500">
          {{ v$.formModel.agreeToConditions.$errors[0].$message }}
        </p>
        <input type="checkbox" v-model="formModel.agreeToConditions" true-value="agree" false-value="" />
        <label class="ml-2 font-medium">I agree to
          <a @click="showConditionAgreement = true" class="link">these conditions</a></label>
      </div>
    </section>
    <button @click="submit" :disabled="!canSubmit" class="button mt-5">
      Submit and Pay {{ totalDollarAmount }}
    </button>
    <p v-if="v$.$error" class="mt-2 text-red-500">
      Oops... There is an issue with the form. Check fields for errors.
    </p>
    <PriceBreakdownModal
      v-if="showPriceBreakdownModal"
      @close="showPriceBreakdownModal = false"
      :pricing="pricing"
      :pricingMeta="pricingMeta"
    />
    <ConditionAgreementModal v-if="showConditionAgreement" @close="showConditionAgreement = false" type="lender" />
    <SubmittingModal
      v-if="isSubmitting"
      @close="(isSubmitting = false)"
      :loading="loading"
      :errorMessage="error"
      :showNextSteps="true"
      :receiptUrl="receiptUrl"
    >
      <template #success>
        <div>
          <p class="text-3xl text-site-purple font-semibold">Sign up complete!</p>
          <p class="text-xl pt-5">Your information has been submitted successfully and the payment of <strong class="font-semibold">{{totalDollarAmount}}</strong> has been processed.</p>
        </div>
      </template>
    </SubmittingModal>
  </StandardPageLayout>
</template>
<script>
import { required, requiredIf, formValidationMixin } from '@/mixins/formValidateMixin'
import _pick from 'lodash/pick'
import StagesModal from '../setup-form/StagesModal'
import { STAGE_SETUP_TYPES, SETUP_TYPE } from '../../../constants'
import { getPricing } from '@/pricing'

const newWebsiteBackend = {
  url: "",
  username: "",
  password: "",
};
const newWebsite = {
  url: "",
};
const newOnlineReview = {
  url: ""
};
export default {
  mixins: [formValidationMixin],
  components: { StagesModal },
  props: ["keeId"],
  data() {
    return {
      loading: false,
      error: "",
      isSubmitting: false,
      showConditionAgreement: false,
      showStagesModal: false,
      showPriceBreakdownModal: false,
      receiptUrl: "",
      formModel: {
        basicInfo: {
          firstName: "",
          lastName: "",
          email: "",
          phone: ""
        },
        setupEnabledFor: "",
        fubUsername: "",
        fubPassword: "",
        websites: [{ ...newWebsite }],
        onlineReviews: [{ ...newOnlineReview }],
        youtube: "",
        companyName: "",
        businessAddress: {
          addressLine1: "",
          addressLine2: "",
          country: "",
          city: "",
          state: "",
          zip: "",
        },
        multiMarketServicing: "",
        fubPixelInstalled: "",
        websiteBackends: [{ ...newWebsiteBackend }],
        stageSetup: "",
        includeSMSDrips: "No",
        textingService: "",
        agreeToConditions: "",
        billing: {
          firstName: "",
          lastName: "",
          addressLine1: "",
          addressLine2: "",
          country: "",
          city: "",
          state: "",
          zip: "",
          phone: "",
        },
      },
    };
  },
  validations() {
    const requiredIfNoKeeId = { required: requiredIf(!this.keeId) }
    return {
      formModel: {
        basicInfo: {
          firstName: requiredIfNoKeeId,
          lastName: requiredIfNoKeeId,
          email: requiredIfNoKeeId,
          phone: requiredIfNoKeeId
        },
        setupEnabledFor: { required },
        fubUsername: { required },
        fubPassword: { required },
        youtube: {},
        companyName: { required },
        businessAddress: {
          addressLine1: { required },
          addressLine2: {},
          country: { required },
          city: { required },
          state: { required },
          zip: { required },
        },
        multiMarketServicing: { required },
        fubPixelInstalled: { required },
        stageSetup: { required },
        textingService: { required: requiredIf(this.includeSMSDrips) },
        agreeToConditions: { required },
        billing: {
          firstName: { required },
          lastName: { required },
          addressLine1: { required },
          addressLine2: {},
          country: { required },
          city: { required },
          state: { required },
          zip: { required },
          phone: {}
        },
      },
    };
  },
  computed: {
    pricing() {
      return getPricing(this.setupType, this.legacyPricing)
    },
    legacyPricing() {
      return this.$route.query.legacyPricing || ''
    },
    pricingMeta() {
      return {
        customizeStages: this.customizeStages,
        customUserEnabling: this.customUserEnabling,
        includeSMSDrips: this.includeSMSDrips
      }
    },
    totalAmount() {
      let amount = this.pricing.BASE
      if (this.customUserEnabling) {
        amount += this.customUserEnablingPricing
      }
      if (this.customizeStages) {
        amount += this.pricing.CUSTOM_STAGES
      }
      if (this.includeSMSDrips) {
        amount += this.smsDripsPricing
        if (this.customizeStages) {
          amount += this.pricing.SMS_DRIPS_CUSTOM_STAGES
        }
      }
      return amount
    },
    totalDollarAmount() {
      return `$${this.totalAmount.toLocaleString('en')}`
    },
    smsDripsPricing() {
      return this.pricing.SMS_DRIPS
    },
    customStagesPricing() {
      return this.pricing.CUSTOM_STAGES
    },
    customUserEnablingPricing() {
      return this.pricing.CUSTOM_USER_ENABLING
    },
    customizeStages() {
      return this.formModel.stageSetup === STAGE_SETUP_TYPES.CUSTOM
    },
    includeSMSDrips() {
      return this.formModel.includeSMSDrips === 'Yes'
    },
    canSubmit() {
      return !this.isSubmitting;
    },
    noFubPixel() {
      return this.formModel.fubPixelInstalled === "No";
    },
    stageSetupTypes() {
      return STAGE_SETUP_TYPES
    },
    customUserEnabling() {
      return !!this.formModel.setupEnabledFor && this.formModel.setupEnabledFor !== 'All users'
    },
    setupType() {
      return SETUP_TYPE.FUB_LENDER_REVAMP
    }
  },
  watch: {
    noFubPixel(newVal, oldVal) {
      if (oldVal && !newVal) {
        this.formModel.websiteBackends = [{ ...newWebsiteBackend }];
      }
    },
  },
  methods: {
    openRouteInNewWindow(routeName) {
      window.open(this.$router.resolve({ name: routeName }).href, '_blank')
    },
    addWebsite() {
      this.formModel.websites.push({ ...newWebsite });
    },
    removeWebsite(idx) {
      this.formModel.websites.splice(idx, 1);
    },
    addOnlineReview() {
      this.formModel.onlineReviews.push({ ...newOnlineReview });
    },
    removeOnlineReview(idx) {
      this.formModel.onlineReviews.splice(idx, 1);
    },
    addBackend() {
      this.formModel.websiteBackends.push({ ...newWebsiteBackend });
    },
    removeBackend(idx) {
      this.formModel.websiteBackends.splice(idx, 1);
    },
    buildNotes() {
      return _pick(this.formModel, [
        'setupEnabledFor',
        'websites',
        'onlineReviews',
        'youtube',
        'companyName',
        'businessAddress',
        'multiMarketServicing',
        'fubPixelInstalled',
        'websiteBackends',
        'stageSetup',
        'includeSMSDrips',
        'textingService'
      ])
    },
    async submit() {
      if (!(await this.v$.$validate())) return

      try {
        const notes = this.buildNotes();
        const billing = this.formModel.billing;
        const { locationId, sourceId } = await this.$refs.squarePayment.tokenize();
        this.error = ""
        this.isSubmitting = true
        this.loading = true

        const { receiptUrl } = await this.$service.createSetup({
          basicInfo: !this.keeId ? this.formModel.basicInfo : null,
          keeId: this.keeId || null,
          fubPassword: this.formModel.fubPassword,
          fubUsername: this.formModel.fubUsername,
          notes,
          meta: {
            setupType: this.setupType,
            customStages: this.customizeStages,
            customUserEnabling: this.customUserEnabling,
            includeSMSDrips: this.includeSMSDrips,
            legacyPricing: this.legacyPricing
          },
          payment: {
            note: 'Lender Setup Payment',
            billing,
            locationId,
            sourceId,
          },
          businessAddress: this.formModel.businessAddress
        })
        this.receiptUrl = receiptUrl
        this.loading = false
      } catch (e) {
        this.error = this.$service.getErrorMessage(e)
        this.loading = false
      }
    },
  },
};
</script>
<style scoped>
section {
  @apply mt-10;
}

label {
  @apply mr-2;
}

.field {
  @apply mt-2;
}

input[type="text"],
input[type="password"],
select {
  @apply border rounded py-1 px-2 w-64;
}

input[type="radio"] {
  @apply mr-2;
}

.field-block-error {
  @apply inline-block border border-red-500 p-2 rounded;
}
</style>
