<template>
  <Modal @close="$emit('close')">
    <h1 class="text-2xl text-center mt-10">Price Breakdown</h1>
    <table class="mt-10 max-w-xl w-full mx-auto">
      <tbody>
        <tr v-for="lineItem, idx in lineItems" :key="`line-item-${idx}`">
          <td class="border-b border-gray-300 py-2 px-3 w-1/2">{{ lineItem.name }}</td>
          <td class="border-b border-gray-300 py-2 px-3 w-1/2 text-right">${{ lineItem.price.toLocaleString('en') }}</td>
        </tr>
        <tr class="font-semibold">
          <td class="py-2 px-3 w-1/2">Total</td>
          <td class="py-2 px-3 w-1/2 text-right">(USD) ${{ total.toLocaleString('en') }}</td>
        </tr>
      </tbody>
    </table>
    <!-- <div v-if="defaultSetup" class="max-w-xl w-full mx-auto mt-10 text-sm text-gray-600">
      <p><span class="font-medium">Deposit:</span> ${{ halfTotal.toLocaleString('en') }} (due now)</p>
      <p class="mt-2"><span class="font-medium">Balance:</span> ${{ halfTotal.toLocaleString('en') }} (due within 2 weeks after the FUB revamp is complete)</p>
    </div> -->
    <div class="flex justify-center mt-5">
      <button class="button mt-5" @click="$emit('close')">Close</button>
    </div>
  </Modal>
</template>
<script>
export default {
  props: [
    'pricing',
    'pricingMeta'
  ],
  computed: {
    lineItems() {
      let lineItems = []
      lineItems.push({
        name: 'Base Price',
        price: this.pricing.BASE
      })

      if (this.pricingMeta.customUserEnabling) {
        lineItems.push({
          name: 'Custom work to enable the setup for a limited set of users in the FUB accout.',
          price: this.pricing.CUSTOM_USER_ENABLING
        })
      }

      if (this.pricingMeta.customizeStages) {
        lineItems.push({
          name: 'Stage Customization (default stage usage opt-out)',
          price: this.pricing.CUSTOM_STAGES
        })
      }

      if (this.pricingMeta.includeSMSDrips) {
        lineItems.push({
          name: 'SMS Drips',
          price: this.pricing.SMS_DRIPS
        })

        if (this.pricingMeta.customizeStages) {
          lineItems.push({
            name: 'Stage Customization on SMS Drips (default stage usage opt-out)',
            price: this.pricing.SMS_DRIPS_CUSTOM_STAGES
          })
        }
      }

      if (this.pricingMeta.includeSpanishActionPlans) {
        lineItems.push({
          name: 'Spanish Action Plans',
          price: this.pricing.SPANISH_ACTION_PLANS
        })
      }

      if (this.pricingMeta.includeLenderAddOn) {
        lineItems.push({
          name: 'Lender Add-on',
          price: this.pricing.LENDER_ADD_ON
        })
      }

      if (this.pricingMeta.customWorkAmount) {
        lineItems.push({
          name: 'Custom Work Amount',
          price: this.customWorkAmount
        })
      }

      if (this.pricingMeta.discountAmount) {
        lineItems.push({
          name: 'Discount Amount',
          price: -this.discountAmount
        })
      }

      return lineItems
    },
    total() {
      return this.lineItems.reduce((accumulator, currentItem) => accumulator + currentItem.price, 0);
    },
    halfTotal() {
      return this.total/2
    }
  }
}
</script>