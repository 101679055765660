<template>
  <div class="h-screen flex items-center justify-center">
    <div v-if="providedPassword" class="text-center">
      <input type="text" v-model="cryptoStr" class="form-input"/>
      <div class="flex justify-center mt-2">
        <button class="p-2" @click="encrypt">Encrypt</button>
        <button class="p-2" @click="decrypt">Decrypt</button>
      </div>
      <p v-if="error">Oops something went wrong.</p>
      <p v-if="copied">Copied!</p>
      <div v-else-if="cryptoVal">
        <p class="mt-5">{{ cryptoVal }}</p>
        <button @click="copyCryptoVal" class="button mt-5">
          <font-awesome-icon icon="fa-solid fa-clipboard" style="font-size: 20px;" />
        </button>
      </div>
    </div>
    <div v-else class="text-center">
      <input type="password" v-model="password" v-on:keyup.enter="proceed" class="form-input" placeholder="password">
      <br>
      <button class="button mt-5" @click="proceed">Next</button>
    </div>
  </div>
</template>
<script>
import { encrypt as encryptRequest, decrypt as decryptRequest } from '@/service'
export default {
  data() {
    return {
      password: '',
      providedPassword: false,
      cryptoStr: '',
      cryptoVal: '',
      error: false,
      copied: false,
    }
  },
  methods: {
    encrypt() {
      if (!this.cryptoStr) {
        alert('No value was provided')
        return
      }
      this.error = false
      this.copied = false

      encryptRequest({
        password: this.password,
        value: this.cryptoStr
      }).then(({ value}) => {
        this.cryptoVal = value
        this.cryptoStr = ''
      }).catch(() => this.error = true)
    },
    decrypt() {
      if (!this.cryptoStr) {
        alert('No value was provided')
        return
      }
      this.error = false
      this.copied = false
      
      decryptRequest({
        password: this.password,
        value: this.cryptoStr
      }).then(({ value }) => {
        this.cryptoVal = value
        this.cryptoStr = ''
      }).catch(() => this.error = true)
    },
    proceed() {
      if (this.password) {
        this.providedPassword = true
      }
    },
    copyCryptoVal() {
      navigator.clipboard.writeText(this.cryptoVal);
      this.copied = true
      this.reset()
    },
    reset() {
      setTimeout(() => { 
        this.copied = false
        this.cryptoStr = ''
        this.cryptoVal = ''
      }, 3000);
    }
  }
}
</script>