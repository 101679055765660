<template>
  <div class="mt-2">
    <label v-if="isDollarAmount" class="mr-2 text-lg font-medium">$</label>
    <input
      type="number"
      :value="v.$model"
      class="border rounded py-1 px-2 w-64"
      :class="{ 'border-red-500': !!errorMessage }"
      @input="handleInput"
    />
    <p v-if="errorMessage" class="text-xs text-red-500">{{ errorMessage }}</p>
  </div>
</template>
<script>
export default {
  props: ["v", "isDollarAmount"],
  computed: {
    errorMessage() {
      return this.v.$error && this.v.$errors[0].$message
    }
  },
  methods: {
    handleInput(e) {
      this.v.$model = e.target.value
    }
  },
};
</script>