<template>
  <StandardPageLayout>
    <p class="text-2xl font-semibold text-site-purple">Thanks for signing up!</p>
    <p class="mt-5"><strong>PLEASE READ - Custom work might be required</strong></p>
    <p>If your team currently operates with multiple Transaction Coordinators who don’t specialize in one part of the transaction pipeline and work with multiple agents then we need to provide you a custom build with custom pricing. Please <a :href="calendlyUrl" class="link">schedule a call here</a> to get signed up instead of submitting this form.</p>
    <p class="mt-5"><strong>Our Transaction Coordinator build is for a USA audience only.</strong></p>
    <p class="mt-5">We will need the following information before we can start the transaction coordinator
      build.</p>
    <!-- Basic Info -->
    <section v-if="!keeId">
      <p class="font-semibold text-lg">Your Info</p>
      <div class="inline-block bg-gray-100 p-5 rounded pt-3">
        <VTextField :v="v$.formModel.basicInfo.firstName" placeholder="First Name" />
        <VTextField :v="v$.formModel.basicInfo.lastName" placeholder="Last Name" />
        <VTextField :v="v$.formModel.basicInfo.email" placeholder="Email" />
        <VTextField :v="v$.formModel.basicInfo.phone" placeholder="Phone Number" />
      </div>
    </section>
    <!-- Follow Up Boss Credentials -->
    <section>
      <p class="font-semibold text-lg">
        Follow Up Boss Credentials
        <span class="text-base font-light text-gray-700">(Owner Account)</span>
      </p>
      <div class="inline-block bg-gray-100 p-5 rounded pt-3">
        <VTextField :v="v$.formModel.fubUsername" placeholder="Username" autocomplete="off" />
        <VTextField :v="v$.formModel.fubPassword" placeholder="Password" autocomplete="off" />
      </div>
    </section>
    <!-- Company -->
    <section>
      <p class="font-semibold text-lg">Company Name</p>
      <div class="inline-block bg-gray-100 p-5 rounded pt-3">
        <VTextField :v="v$.formModel.company" placeholder="Jane Doe Group - KW Realty" class="text-sm" />
      </div>
    </section>
    <!-- Online Reviews -->
    <section>
      <p class="font-semibold text-lg">Online Reviews <span class="text-base font-light text-gray-700">(Please provide
          any URLs to your online reviews i.e Google, Zillow, Facebook etc.)</span></p>
      <!-- <p class="text-base font-light text-gray-700">This is so you can ask for reviews in the post closing drips and build trust with potential clients in the nuture drips</p> -->
      <div class="inline-block">
        <div class="field bg-gray-100 p-5" v-for="(onlineReview, idx) in formModel.onlineReviews"
          :key="`online_review_${idx}`" :class="{ 'mt-5': idx !== 0 }">
          <input type="text" v-model="onlineReview.url" placeholder="http://review-url.com" />
          <span v-if="idx !== 0" class="cursor-pointer ml-2" @click="removeOnlineReview(idx)">
            <font-awesome-icon icon="fa-solid fa-times" />
          </span>
        </div>
      </div>
      <p class="mt-5 font-semibold text-sm">Have more online review URLs?</p>
      <a @click="addOnlineReview" class="
        text-site-purple
        font-semibold
        mt-1
        inline-block
        underline
        cursor-pointer
      ">
        <font-awesome-icon icon="fa-solid fa-plus-circle" />
        Add Online Review URL
      </a>
    </section>
    <!-- Business Address -->
    <section>
      <p class="font-semibold text-lg">Business Address</p>
      <div class="inline-block bg-gray-100 p-5 rounded pt-3">
        <VTextField :v="v$.formModel.businessAddress.addressLine1" placeholder="Address Line 1" />
        <VTextField :v="v$.formModel.businessAddress.addressLine2" placeholder="Address Line 2" />
        <CountrySelect v-model="formModel.businessAddress.country"
          :errorMessage="v$.formModel.businessAddress.country.$error ? v$.formModel.businessAddress.country.$errors[0].$message : ''" />
        <VTextField :v="v$.formModel.businessAddress.city" placeholder="City" />
        <StateSelect v-model="formModel.businessAddress.state"
          :errorMessage="v$.formModel.businessAddress.state.$error ? v$.formModel.businessAddress.state.$errors[0].$message : ''"
          :showProvinces="formModel.businessAddress.country === 'Canada'" />
        <VTextField :v="v$.formModel.businessAddress.zip"
          :placeholder="formModel.businessAddress.country === 'Canada' ? 'Postal Code' : 'Zip Code'" />
      </div>
    </section>
    <!-- Servicing Multiple Markets -->
    <section>
      <p class="font-semibold text-lg">Do you service more than one market (multiple states)?</p>
      <div class="field" :class="{ 'field-block-error': v$.formModel.multiMarketServicing.$error }">
        <p v-if="v$.formModel.multiMarketServicing.$error" class="field-error text-xs text-red-500">
          {{ v$.formModel.multiMarketServicing.$errors[0].$message }}
        </p>
        <input type="radio" value="Yes" v-model="formModel.multiMarketServicing" />
        <label>Yes</label>
        <br />
        <input type="radio" value="No" v-model="formModel.multiMarketServicing" />
        <label>No</label>
      </div>
    </section>
    <!-- ETA State -->
    <section>
      <p class="font-semibold text-lg">Who handles closing?</p>
      <div class="field" :class="{ 'field-block-error': v$.formModel.closing.$error }">
        <p v-if="v$.formModel.closing.$error" class="field-error text-xs text-red-500">
          {{ v$.formModel.closing.$errors[0].$message }}
        </p>
        <input type="radio" value="Escrow" v-model="formModel.closing" />
        <label>Escrow</label>
        <br />
        <input type="radio" value="Title" v-model="formModel.closing" />
        <label>Title</label>
        <br />
        <input type="radio" value="Attorney" v-model="formModel.closing" />
        <label>Attorney</label>
      </div>
    </section>
    <!-- Deposit Holding -->
    <section>
      <p class="font-semibold text-lg">Where are buyer deposits held?</p>
      <div class="inline-block bg-gray-100 p-5 rounded pt-3">
        <DepositSelect v-model="formModel.depositHolding"
          :errorMessage="v$.formModel.depositHolding.$error ? v$.formModel.depositHolding.$errors[0].$message : ''" />
      </div>
    </section>
    <section v-if="formModel.depositHolding === 'Other (specify)'">
      <p class="font-semibold text-lg">Specify buyer deposit holding for your state</p>
      <div class="inline-block bg-gray-100 p-5 rounded pt-3">
        <VTextField :v="v$.formModel.depositHoldingSpecify" />
      </div>
    </section>
    <!-- Team of Agents -->
    <section>
      <p class="font-semibold text-lg">Do you have a team of agents that work under you?</p>
      <div class="field" :class="{ 'field-block-error': v$.formModel.teamOfAgents.$error }">
        <p v-if="v$.formModel.teamOfAgents.$error" class="field-error text-xs text-red-500">
          {{ v$.formModel.teamOfAgents.$errors[0].$message }}
        </p>
        <input type="radio" value="Yes" v-model="formModel.teamOfAgents" />
        <label>Yes</label>
        <br />
        <input type="radio" value="No" v-model="formModel.teamOfAgents" />
        <label>No</label>
      </div>
    </section>
    <!-- Team Leader -->
    <section v-if="formModel.teamOfAgents === 'Yes'">
      <p class="font-semibold text-lg">Congrats emails should send from (specify team leader below)</p>
      <div class="inline-block bg-gray-100 p-5 rounded pt-3">
        <VTextField :v="v$.formModel.teamLeader" placeholder="team leader" />
      </div>
    </section>
    <!-- Transaction Coordinator -->
    <section>
      <p class="font-semibold text-lg">Do you have a transaction coordinator?</p>
      <div class="field" :class="{ 'field-block-error': v$.formModel.transactionCoordinator.$error }">
        <p v-if="v$.formModel.transactionCoordinator.$error" class="field-error text-xs text-red-500">
          {{ v$.formModel.transactionCoordinator.$errors[0].$message }}
        </p>
        <input type="radio" value="Yes" v-model="formModel.transactionCoordinator" />
        <label>Yes</label>
        <br />
        <input type="radio" value="No" v-model="formModel.transactionCoordinator" />
        <label>No</label>
      </div>
    </section>
    <!-- TC List -->
    <section v-if="formModel.transactionCoordinator === 'Yes'">
      <p class="font-semibold text-lg">List transaction coordinators below</p>
      <div v-for="tc in formModel.tcList" :key="tc.name" class="mt-2 flex items-center bg-white p-5 shadow max-w-3xl text-sm">
        <div class="flex-grow"><strong>{{ tc.name }}</strong> works with <strong>{{ tc.type }}</strong></div>
        <font-awesome-icon icon="fa-solid fa-trash" class="ml-5 text-red-500 cursor-pointer" @click="deleteTC(tc.name)" />
      </div>
      <a @click="stageTC" class="
        text-site-purple
        font-semibold
        mt-1
        inline-block
        underline
        cursor-pointer
      ">
        <font-awesome-icon icon="fa-solid fa-plus-circle" />
        Add Transaction Coordinator
      </a>
      <Modal v-if="showTCModal" size="sm" @close="showTCModal = false">
        <div class="flex flex-col items-center">
          <p class="text-center text-lg font-semibold">Tell us about the transaction coordinator</p>
          <input type="text" v-model="stagedTC.name" placeholder="Name" class="mt-5" />
          <p class="mt-5">works with...</p>
          <select v-model="stagedTC.type" class="mt-5" style="appearance: revert;">
            <option disabled value="">select work type</option>
            <option>Listing Coordination</option>
            <option>Selling Transactions</option>
            <option>Buying Transactions</option>
            <option>All - Listing Coordination + Selling/Buying Transactions</option>
          </select>
          <button class="button mt-5" @click="addTC" :disabled="stagedTC.name === '' || stagedTC.type === ''">Add</button>
        </div>
      </Modal>
    </section>
    <!-- ISA Compensation -->
    <section v-if="formModel.transactionCoordinator === 'Yes'">
      <p class="font-semibold text-lg">Does your team consist of ISAs that receive compensation from closed transactions?</p>
      <div class="field" :class="{ 'field-block-error': v$.formModel.isaCompensation.$error }">
        <p v-if="v$.formModel.isaCompensation.$error" class="field-error text-xs text-red-500">
          {{ v$.formModel.isaCompensation.$errors[0].$message }}
        </p>
        <input type="radio" value="Yes" v-model="formModel.isaCompensation" />
        <label>Yes</label>
        <br />
        <input type="radio" value="No" v-model="formModel.isaCompensation" />
        <label>No</label>
      </div>
    </section>
    <!-- Stage setup -->
    <section>
      <p class="font-semibold text-lg">Setting up stages for the revamp</p>
      <div class="field" :class="{ 'field-block-error': v$.formModel.stageSetup.$error }">
        <p v-if="v$.formModel.stageSetup.$error" class="field-error text-xs text-red-500">
          {{ v$.formModel.stageSetup.$errors[0].$message }}
        </p>
        <input type="radio" :value="stageSetupTypes.DEFAULT" v-model="formModel.stageSetup" />
        <label>Use the default stages provided by the TC Build (recommended for most)</label>
        <br />
        <input type="radio" :value="stageSetupTypes.ZILLOW_TWO_WAY_SYNC" v-model="formModel.stageSetup" />
        <label>I want to use the Zillow Stages via the 2-way sync with Zillow - more info <a class="link"
            href="https://help.followupboss.com/hc/en-us/articles/8895957403927" target="_blank">here</a></label>
        <br />
        <input type="radio" :value="stageSetupTypes.CUSTOM" v-model="formModel.stageSetup" />
        <label>I would like to opt out of using the default stages used by the TC Build. Instead, I have a requirement
          that the build be customized to use predefined stages that I will provide to Kee Technology Solutions. I
          acknowledge that I must provide names of my stages before work on the revamp can be started and <strong>I
            agree to pay a ${{ customStagesPricing }} customization fee.</strong> I acknowledge that my review is
          required before going live with the build out. (Note: We will reach out to you after your submission to obtain
          a list of stages we can use. We will reach back out after the setup is complete to review the setup with you
          before we go live with it.)</label>
      </div>
    </section>
    <!-- Setup activation notice -->
    <div class="p-10 mt-10 border bg-gray-100">
      <h3 class="text-lg font-semibold">Activating your new setup</h3>
      <p class="mt-5">After the setup of your new action plans and automations is complete, we will notify you via
        email. At this stage, you can either approve immediate activation or opt for a review period to evaluate the
        setup before it goes live.</p>
    </div>
    <!-- Project Price -->
    <section class="inline-block border p-10 bg-gray-100 text-gray-700">
      <p class="text-xl font-medium">Project Price</p>
      <p class="text-lg font-medium mt-2">{{ totalDollarAmount }}</p>
      <div class="mt-5 text-sm w-72">
        <p><strong>Breakdown</strong></p>
        <p class="border-b py-2 flex justify-between"><span>Base Price:</span><span>${{ basePrice }}</span></p>
        <p v-if="customizeStages" class="border-b py-2 flex justify-between"><span>Custom Stages:</span><span>${{ customStagesPricing }}</span></p>
        <div v-if="formModel.tcList.length > 0">
          <p class="border-b py-2 flex justify-between"><span>First TC Seat:</span><span>${{ pricing.FIRST_TC_SEAT.toLocaleString('en') }}</span></p>
          <p class="border-b py-2 flex justify-between" v-for="item, idx in formModel.tcList.slice(1)" :key="idx">
            <span>Add TC Seat:</span><span>${{ pricing.ADDITIONAL_TC_SEAT.toLocaleString('en') }}</span>
          </p>
        </div>
      </div>
    </section>
    <!-- Payment -->
    <section>
      <div class="mt-5">
        <p class="text-2xl font-semibold">
          Payment
        </p>
        <p class="text-lg font-semibold mt-5">
          Amount: {{ totalDollarAmount }} <span class="text-sm font-normal">(USD)</span>
        </p>
      </div>
      <div class="inline-block bg-gray-100 p-5 rounded pt-3 mt-5">
        <p class="font-semibold">Billing Information</p>
        <VTextField :v="v$.formModel.billing.firstName" placeholder="First Name" />
        <VTextField :v="v$.formModel.billing.lastName" placeholder="Last Name" />
        <VTextField :v="v$.formModel.billing.email" placeholder="Email" />
        <VTextField :v="v$.formModel.billing.addressLine1" placeholder="Address Line 1" />
        <VTextField :v="v$.formModel.billing.addressLine2" placeholder="Address Line 2" />
        <CountrySelect v-model="formModel.billing.country"
          :errorMessage="v$.formModel.billing.country.$error ? v$.formModel.billing.country.$errors[0].$message : ''" />
        <VTextField :v="v$.formModel.billing.city" placeholder="City" />
        <StateSelect v-model="formModel.billing.state"
          :errorMessage="v$.formModel.billing.state.$error ? v$.formModel.billing.state.$errors[0].$message : ''"
          :showProvinces="formModel.billing.country === 'Canada'" />
        <VTextField :v="v$.formModel.billing.zip"
          :placeholder="formModel.billing.country === 'Canada' ? 'Postal Code' : 'Zip Code'" />
        <VTextField :v="v$.formModel.billing.phone" placeholder="Phone" />
        <p class="mt-5 font-semibold">Card Information</p>
        <SquarePayment ref="squarePayment" />
      </div>
    </section>
    <!-- Condition Agreement -->
    <section>
      <div class="field" :class="{ 'field-block-error': v$.formModel.agreeToConditions.$error }">
        <p v-if="v$.formModel.agreeToConditions.$error" class="field-error text-xs text-red-500">
          {{ v$.formModel.agreeToConditions.$errors[0].$message }}
        </p>
        <input type="checkbox" v-model="formModel.agreeToConditions" true-value="agree" false-value="" />
        <label class="ml-2 font-medium">I agree to
          <a @click="showConditionAgreement = true" class="link">these conditions</a></label>
      </div>
      <ConditionAgreementModal type="tc-assist" v-if="showConditionAgreement" @close="showConditionAgreement = false" />
    </section>
    <button @click="submit" :disabled="isSubmitting" class="button mt-5">
      Submit and pay {{ totalDollarAmount }}
    </button>
    <p v-if="v$.$error" class="mt-2 text-red-500">
      Oops... There is an issue with the form. Check fields for errors.
    </p>
    <SubmittingModal
      v-if="isSubmitting"
      @close="(isSubmitting = false)"
      :loading="loading"
      :errorMessage="error"
      :showNextSteps="true"
      :receiptUrl="receiptUrl"
    >
      <template #success>
        <div>
          <p class="text-3xl text-site-purple font-semibold">Sign up complete!</p>
          <p class="text-xl pt-5">Your information has been submitted successfully and the payment of <strong class="font-semibold">{{totalDollarAmount}}</strong> has been processed.</p>
        </div>
      </template>
    </SubmittingModal>
  </StandardPageLayout>
</template>
<script>
import { required, requiredIf, formValidationMixin } from '@/mixins/formValidateMixin'
import { STAGE_SETUP_TYPES, SETUP_TYPE, CALENDLY_CALENDARS } from '../../../constants';
import { getPricing } from '@/pricing'
import DepositSelect from './DepositSelect.vue';
import _pick from 'lodash/pick'

class TransactionCoordinator {
  constructor({ name, type }) {
    this.name = name
    this.type = type
  }
}

const newOnlineReview = {
  url: ""
};

export default {
  mixins: [formValidationMixin],
  components: { DepositSelect },
  data() {
    return {
      loading: false,
      error: "",
      formModel: {
        basicInfo: {
          firstName: "",
          lastName: "",
          email: "",
          phone: ""
        },
        fubUsername: "",
        fubPassword: "",
        company: "",
        onlineReviews: [{ ...newOnlineReview }],
        businessAddress: {
          addressLine1: "",
          addressLine2: "",
          country: "",
          city: "",
          state: "",
          zip: "",
        },
        multiMarketServicing: "",
        closing: "",
        depositHolding: "",
        depositHoldingSpecify: "",
        teamOfAgents: "",
        teamLeader: "",
        transactionCoordinator: "",
        tcList: [],
        isaCompensation: "",
        stageSetup: "",
        billing: {
          firstName: "",
          lastName: "",
          email: "",
          addressLine1: "",
          addressLine2: "",
          country: "",
          city: "",
          state: "",
          zip: "",
          phone: "",
        },
        agreeToConditions: "",
      },
      receiptUrl: "",
      stagedTC: null,
      isSubmitting: false,
      showTCModal: false,
      showConditionAgreement: false,
    }
  },
  validations() {
    const requiredIfNoKeeId = { required: requiredIf(!this.keeId) }
    return {
      formModel: {
        basicInfo: {
          firstName: requiredIfNoKeeId,
          lastName: requiredIfNoKeeId,
          email: requiredIfNoKeeId,
          phone: requiredIfNoKeeId
        },
        fubUsername: { required },
        fubPassword: { required },
        company: { required },
        businessAddress: {
          addressLine1: { required },
          addressLine2: {},
          country: { required },
          city: { required },
          state: { required },
          zip: { required },
        },
        multiMarketServicing: { required },
        closing: { required },
        depositHolding: { required },
        depositHoldingSpecify: { required: requiredIf(this.formModel.depositHolding === 'Other (specify)') },
        teamOfAgents: { required },
        teamLeader: { required: requiredIf(this.formModel.teamOfAgents === 'Yes') },
        transactionCoordinator: { required },
        isaCompensation: { required: requiredIf(this.formModel.transactionCoordinator === 'Yes') },
        stageSetup: { required },
        billing: {
          firstName: { required },
          lastName: { required },
          email: { required: requiredIf(this.subscription) },
          addressLine1: { required },
          addressLine2: {},
          country: { required },
          city: { required },
          state: { required },
          zip: { required },
          phone: {}
        },
        agreeToConditions: { required },
      }
    }
  },
  computed: {
    keeId() {
      return this.$route.params.keeId
    },
    setupType() {
      return SETUP_TYPE.TC_ASSIST
    },
    calendlyUrl() {
      return `https://calendly.com/${CALENDLY_CALENDARS.TC_ASSIST_DISCOVERY}`
    },
    pricing() {
      return getPricing(this.setupType)
    },
    basePrice() {
      return this.pricing.BASE.toLocaleString('en')
    },
    stageSetupTypes() {
      return STAGE_SETUP_TYPES
    },
    customizeStages() {
      return this.formModel.stageSetup === STAGE_SETUP_TYPES.CUSTOM
    },
    customStagesPricing() {
      return this.pricing.CUSTOM_STAGES
    },
    totalAmount() {
      let amount = this.pricing.BASE
      if (this.tcCount > 0) {
        amount += this.pricing.FIRST_TC_SEAT
        const additionalSeats = this.tcCount - 1
        if (additionalSeats > 0) {
          amount += additionalSeats * this.pricing.ADDITIONAL_TC_SEAT
        }
      }
      if (this.customizeStages) {
        amount += this.customStagesPricing
      }
      return amount
    },
    totalDollarAmount() {
      return `$${this.totalAmount.toLocaleString('en')}`
    },
    tcCount() {
      return this.formModel.tcList.length
    },
  },
  methods: {
    stageTC() {
      this.stagedTC = new TransactionCoordinator({ name: "", type: "" })
      this.showTCModal = true
    },
    addTC() {
      this.formModel.tcList.push(this.stagedTC)
      this.showTCModal = false
    },
    deleteTC(tcName) {
      this.formModel.tcList = this.formModel.tcList.filter(({ name }) => name !== tcName)
    },
    addOnlineReview() {
      this.formModel.onlineReviews.push({ ...newOnlineReview });
    },
    removeOnlineReview(idx) {
      this.formModel.onlineReviews.splice(idx, 1);
    },
    buildNotes() {
      return _pick(this.formModel, [
        'company',
        'businessAddress',
        'onlineReviews',
        'multiMarketServicing',
        'closing',
        'depositHolding',
        'depositHoldingSpecify',
        'teamOfAgents',
        'teamLeader',
        'transactionCoordinator',
        'tcList',
        'isaCompensation',
        'stageSetup',
      ])
    },
    async submit() {
      if (!(await this.v$.$validate())) return

      try {
        const notes = this.buildNotes()
        const billing = this.formModel.billing
        const { locationId, sourceId } = await this.$refs.squarePayment.tokenize()
        this.error = ""
        this.isSubmitting = true
        this.loading = true

        const { receiptUrl } = await this.$service.createSetup({
          keeId: this.keeId || null,
          basicInfo: !this.keeId ? this.formModel.basicInfo : null,
          fubPassword: this.formModel.fubPassword,
          fubUsername: this.formModel.fubUsername,
          notes,
          meta: {
            setupType: this.setupType,
            customStages: this.customizeStages,
            tcCount: this.tcCount
          },
          payment: {
            note: 'TC Assist Setup Payment',
            billing,
            locationId,
            sourceId,
          },
          businessAddress: this.formModel.businessAddress
        })
        
        this.receiptUrl = receiptUrl
        this.loading = false
      } catch (e) {
        this.error = this.$service.getErrorMessage(e)
        this.loading = false
      }
    },
  }
}
</script>
<style scoped>
section {
  @apply mt-10;
}

label {
  @apply mr-2;
}

.field {
  @apply mt-2;
}

input[type="text"],
input[type="password"],
select {
  @apply border rounded py-1 px-2 w-64;
}

input[type="radio"] {
  @apply mr-2;
}

.field-block-error {
  @apply inline-block border border-red-500 p-2 rounded;
}
</style>