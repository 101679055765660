<template>
  <StandardPageLayout :transparentMain="true">
    <div class="bg-white p-10 rounded-lg shadow">
      <Modal v-if="accountRetreivalFailed" class="text-center">Failed to retreive account.</Modal>
      <p class="text-2xl font-semibold">Thanks for signing up!</p>
      <p class="text-lg mt-2">We will need the following information to get your VAs rolling with BossAssist.</p>
      <!-- Basic Info -->
      <section v-if="!keeId" class="mt-10">
        <p class="font-semibold text-lg">Billing Contact</p>
        <div class="inline-block bg-gray-100 p-5 rounded pt-3">
          <VTextField :v="v$.basicInfo.firstName" placeholder="First Name" />
          <VTextField :v="v$.basicInfo.lastName" placeholder="Last Name" />
          <VTextField :v="v$.basicInfo.email" placeholder="Email" />
          <VTextField :v="v$.basicInfo.phone" placeholder="Phone Number" />
        </div>
      </section>
      <!-- VA List -->
      <section class="mt-10">
        <hr class="my-10">
        <p class="font-semibold text-lg">Virtual Assistant(s) to be trainied</p>
        <p v-if="!isPromo" class="mt-2">Signing up more than one virtual assistant? See our pricing structure <a class="link" @click="showPricingStructure = true">here</a></p>
        <p v-if="!isPromo && currentVACount > 0" class="mt-2 text-sm font-medium"><strong>Note:</strong> Our records show that you have previously signed up {{ currentVACount }} virtual assistants. Pricing is automatically adjusted accordingly.</p>
        <Modal v-if="showPricingStructure" @close="showPricingStructure = false" size="sm">
          <div class="text-lg font-medium">
            <div class="flex justify-between items-center border-b p-2" v-for="{description, price} in pricingTiers" :key="`tier_${price}`"><div>{{ description }}</div><div>${{ price.toLocaleString('en') }} <span class="font-normal text-gray-600 text-sm">each</span></div></div>
          </div>
        </Modal>
        <div v-for="va, idx in vaList" :key="va" class="bg-white mt-2 max-w-2xl border-l-8 border-2 border-site-purple p-1 px-2 shadow flex justify-between items-center">
          <div>
            <h1 class="text-lg">{{ va.firstName }} {{ va.lastName }}</h1>
            <div class="text-xs">{{ va.email }} <span v-if="va.phone">| {{ va.phone }}</span></div>  
          </div>
          <div v-if="!isPromo" class="flex items-center">
            <div class="text-sm font-semibold text-site-purple">Training Cost: ${{ getTrainingCost(idx).toLocaleString('en') }}</div>
            <font-awesome-icon icon="fa-solid fa-times" class="cursor-pointer ml-5" @click="deleteVA(idx)" />
          </div>
        </div>
        <button class="link mt-2" @click="addingVA = true"><font-awesome-icon icon="fa-solid fa-plus-circle" /> Add Virtual Assistant</button>
      </section>
      <Modal v-if="addingVA" @close="addingVA = false" size="sm" class="text-center">
        <h1 class="text-xl font-semibold mb-5">Virtual Assistant Info</h1>
        <input type="text" class="form-input" placeholder="First Name" ref="vaRefFirstName" />
        <br>
        <input type="text" class="form-input" placeholder="Last Name" ref="vaRefLastName" />
        <br>
        <input type="text" class="form-input" placeholder="Email" ref="vaRefEmail" />
        <br>
        <input type="text" class="form-input" placeholder="Phone (Optional)" ref="vaRefPhone" />
        <br>
        <button class="button mt-5" @click="addVA">Add</button>
      </Modal>
      <!-- Promo -->
      <section v-if="isPromo" class="mt-10">
        <hr class="my-10">
        <p class="font-semibold text-lg">Promo Code</p>
        <div class="inline-block bg-gray-100 p-5 rounded pt-3">
          <VTextField :v="v$.promoCode" placeholder="Promo Code" />
        </div>
        <br>
        <button @click="submitPromo" :disabled="vaList.length < 1 || !promoCode || isSubmitting" class="button mt-5">Submit</button>
        <p v-if="v$.$error" class="mt-2 text-red-500">Oops... There is an issue with the form. Check fields for errors.</p>
       </section>
      <!-- Payment -->
      <section v-else-if="totalTrainingCost > 0" class="mt-10">
        <hr class="my-10">
        <p class="font-semibold text-lg">Payment Amount: ${{totalTrainingCost.toLocaleString('en')}} <span class="text-sm font-normal">(USD)</span></p>
        <div class="inline-block bg-gray-100 p-5 rounded pt-3 mt-5">
          <p class="font-semibold">Billing Information</p>
          <VTextField :v="v$.billing.firstName" placeholder="First Name" />
          <VTextField :v="v$.billing.lastName" placeholder="Last Name" />
          <VTextField :v="v$.billing.email" placeholder="Email" />
          <VTextField :v="v$.billing.addressLine1" placeholder="Address Line 1" />
          <VTextField :v="v$.billing.addressLine2" placeholder="Address Line 2" />
          <CountrySelect
            v-model="billing.country"
            :errorMessage="v$.billing.country.$error ? v$.billing.country.$errors[0].$message : ''"
          />
          <VTextField :v="v$.billing.city" placeholder="City" />
          <StateSelect
            v-model="billing.state"
            :errorMessage="v$.billing.state.$error ? v$.billing.state.$errors[0].$message : ''"
            :showProvinces="billing.country === 'Canada'"
          />
          <VTextField :v="v$.billing.zip" :placeholder="billing.country === 'Canada' ? 'Postal Code' : 'Zip Code'" />
          <VTextField :v="v$.billing.phone" placeholder="Phone" />
          <p class="mt-5 font-semibold">Card Information</p>
          <SquarePayment ref="squarePayment" />
        </div>
        <br>
        <button @click="submit" :disabled="isSubmitting" class="button mt-5">
          Submit Payment
        </button>
        <p v-if="v$.$error" class="mt-2 text-red-500">
          Oops... There is an issue with the form. Check fields for errors.
        </p>
      </section>
      <SubmittingModal
        v-if="isSubmitting"
        @close="(isSubmitting = false)"
        :loading="loading"
        :errorMessage="error"
        :receiptUrl="receiptUrl"
        :noPayment="isPromo"
        redirect="/boss-assist"
      >
        <template #success>
          <div>
            <p class="text-3xl text-site-purple font-semibold">Sign up complete!</p>
            <p v-if="isPromo" class="text-xl pt-5">Your information has been submitted successfully.</p>
            <p v-else class="text-xl pt-5">Your information has been submitted successfully and the payment of <strong class="font-semibold">${{totalTrainingCost.toLocaleString('en')}}</strong> has been processed.</p>
          </div>
        </template>
      </SubmittingModal>
    </div>
  </StandardPageLayout>
</template>
<script>
import { requiredIf, formValidationMixin } from '@/mixins/formValidateMixin'
import { SETUP_TYPE } from '../../../constants';
import { bossAssistPricingTiers } from '../../../pricing';

export default {
  mixins: [formValidationMixin],
  data() {
    return {
      loading: false,
      error: "",
      isSubmitting: false,
      accountRetreivalFailed: false,
      receiptUrl: "",
      currentVACount: null,
      addingVA: false,
      showPricingStructure: false,
      basicInfo: {
        firstName: "",
        lastName: "",
        email: "",
        phone: ""
      },
      promoCode: "",
      billing: {
        firstName: "",
        lastName: "",
        email: "",
        addressLine1: "",
        addressLine2: "",
        country: "",
        city: "",
        state: "",
        zip: "",
        phone: "",
      },
      vaList: []
    }
  },
  validations() {
    const requiredIfNoKeeId = { required: requiredIf(!this.keeId) }
    const requiredIfNoPromo = { required: requiredIf(!this.isPromo) }
    return {
      basicInfo: {
        firstName: requiredIfNoKeeId,
        lastName: requiredIfNoKeeId,
        email: requiredIfNoKeeId,
        phone: requiredIfNoKeeId
      },
      promoCode: { required: requiredIf(this.isPromo) },
      billing: {
        firstName: requiredIfNoPromo,
        lastName: requiredIfNoPromo,
        email: {},
        addressLine1: requiredIfNoPromo,
        addressLine2: {},
        country: requiredIfNoPromo,
        city: requiredIfNoPromo,
        state: requiredIfNoPromo,
        zip: requiredIfNoPromo,
        phone: {}
      },
    }
  },
  async mounted() {
    if (this.keeId) {
      try {
        const { meta: { vaCount } } = await this.$service.getPerson(this.keeId)
        this.currentVACount = vaCount
      } catch (e) {
        this.accountRetreivalFailed = true
      }
    }
  },
  computed: {
    keeId() {
      return this.$route.params.keeId || null
    },
    setupType() {
      return SETUP_TYPE.BOSS_ASSIST
    },
    pricingTiers() {
      return bossAssistPricingTiers
    },
    totalTrainingCost() {
      return this.vaList.reduce((total, _, idx) => {
        return total + this.getTrainingCost(idx);
      }, 0);
    },
    isPromo() {
      return this.$route.query.promo === 'true'
    }
  },
  methods: {
    addVA() {
      const firstName = this.$refs.vaRefFirstName.value
      const lastName = this.$refs.vaRefLastName.value
      const email = this.$refs.vaRefEmail.value
      const phone = this.$refs.vaRefPhone.value
      if (!firstName || !lastName || !email ) return

      this.vaList.push({ firstName, lastName, email, phone })
      this.addingVA = false
    },
    deleteVA(idx) {
      this.vaList.splice(idx, 1)
    },
    getTrainingCost(idx) {
      const costIdx = idx + this.currentVACount
      if (costIdx < 1) {
        return 1200;
      } else if (costIdx < 2) {
        return 1100;
      } else {
        return 1000;
      }
    },
    async submitPromo() {
      if (!(await this.v$.$validate())) return

      try {
        this.error = ""
        this.isSubmitting = true
        this.loading = true

        await this.$service.bossAssistSignup({
          keeId: this.keeId,
          basicInfo: !this.keeId ? this.basicInfo : null,
          vaList: this.vaList,
          currentVACount: this.currentVACount,
          meta: {
            setupType: this.setupType,
            promoCode: this.promoCode
          }
        })

        this.loading = false
      } catch (e) {
        this.error = this.$service.getErrorMessage(e)
        this.loading = false
      }
    },
    async submit() {
      if (!(await this.v$.$validate())) return

      try {
        const { locationId, sourceId } = await this.$refs.squarePayment.tokenize()
        this.error = ""
        this.isSubmitting = true
        this.loading = true

        const { receiptUrl } = await this.$service.bossAssistSignup({
          keeId: this.keeId,
          basicInfo: !this.keeId ? this.basicInfo : null,
          vaList: this.vaList,
          currentVACount: this.currentVACount,
          payment: {
            note: 'BossAssist Payment',
            locationId,
            sourceId,
            billing: this.billing
          },
          meta: {
            setupType: this.setupType
          }
        })
        
        this.receiptUrl = receiptUrl
        this.loading = false
      } catch (e) {
        this.error = this.$service.getErrorMessage(e)
        this.loading = false
      }
    },
  }
}
</script>