<template>
  <div class="mt-2">
    <SelectWrapper>
      <select :value="value" @input="$emit('input', $event.target.value)" :class="{ 'border-red-500': errorMessage, 'text-gray-400': value === '' }" class="border rounded p-1 w-64">
        <option disabled value="">Select Sales Rep.</option>
        <option v-for="item in items" :key="item" :value="item">{{ item }}</option>
      </select>
    </SelectWrapper>
    <p v-if="errorMessage" class="text-xs text-red-500">{{ errorMessage }}</p>
  </div>
</template>
<script>
import { SALES_TEAM } from '../../../constants'
import SelectWrapper from './SelectWrapper'
export default {
  components: { SelectWrapper },
  props: {
    errorMessage: {
      type: String,
      required: false,
      default: ""
    },
    value: {
      type: String,
      required: true,
    },
  },
  computed: {
    items() {
      return SALES_TEAM
    }
  }
}
</script>