<template>
  <div class="mt-2">
    <input
      type="text"
      :value="v.$model"
      class="border rounded py-1 px-2"
      :class="fieldClass"
      @input="handleInput"
      :placeholder="placeholder"
      :autocomplete="autocomplete || 'on'"
    />
    <p v-if="errorMessage" class="text-xs text-red-500">{{ errorMessage }}</p>
  </div>
</template>
<script>
export default {
  props: ["v", "placeholder", "autocomplete", "size"],
  computed: {
    errorMessage() {
      return this.v.$error && this.v.$errors[0].$message
    },
    sizeClass() {
      switch (this.size) {
        case 'full': return 'w-full h-12'
      }
      return 'w-64'
    },
    errorClass() {
      return this.errorMessage ? 'border-red-500' : ''
    },
    fieldClass() {
      return `${this.sizeClass} ${this.errorClass}` 
    }
  },
  methods: {
    handleInput(e) {
      this.v.$model = e.target.value
    }
  },
};
</script>