<template>
  <PromoLayout promo="Sierra Waitlist" buttonText="Join the Waitlist!">
    <div class="text-2xl uppercase text-center">
      <div>Boost your success</div>
      <div class="bg-black text-white font-semibold py-1 px-3 inline-block mt-2">Streamline your Sierra</div>
    </div>
    <ul class="text-lg mt-5 list-disc list-inside">
      <li>Start meaningful conversations.</li>
      <li>Obtain a realistic daily workflow.</li>
      <li>Ensure no lead gets lost in your system.</li>
    </ul>
    <p class="mt-5">Kee Technology Solutions is excited to announce a CRM Revamp for Sierra.</p>
    <p class="mt-5">The revamp will provide you with tried-and-true automation paired with email content that's empathetic, personable, human, and generates responses.</p>
    <p class="mt-5">Join the CRM Revamp waitlist by providing your info below.</p>
  </PromoLayout>
</template>
<script>
import PromoLayout from '../../layouts/PromoLayout.vue';

export default {
  components: { PromoLayout }
}
</script>