import useVuelidate from "@vuelidate/core"
import {
  required as defaultRequired,
  requiredIf as defaultRequiredIf,
  helpers,
} from "@vuelidate/validators"

import VTextField from '@/components/common/form/VTextField'
import VTextArea from '@/components/common/form/VTextArea'
import VNumberField from '@/components/common/form/VNumberField'
import StateSelect from '@/components/common/form/StateSelect'
import CountrySelect from '@/components/common/form/CountrySelect'
import SubmittingModal from '@/components/common/SubmittingModal'
import SquarePayment from '@/components/common/SquarePayment'
import ConditionAgreementModal from '@/components/common/ConditionAgreementModal'
import PriceBreakdownModal from "@/components/common/PriceBreakdownModal";

const requiredMessage = "required";
export const required = helpers.withMessage(requiredMessage, defaultRequired);
export const requiredIf = (statement) =>
  helpers.withMessage(requiredMessage, defaultRequiredIf(statement));

export const formValidationMixin = {
  components: {
    VTextField,
    VTextArea,
    VNumberField,
    StateSelect,
    CountrySelect,
    SubmittingModal,
    SquarePayment,
    ConditionAgreementModal,
    PriceBreakdownModal
  },
  setup() {
    return { v$: useVuelidate() };
  },
}