<template>
  <StandardPageLayout>
    <div v-if="personError">
      <p class="text-center mt-10 text-xl">Could not find account.</p>
    </div>
    <div v-else-if="personLoaded">
      <p class="text-2xl font-semibold text-site-purple">Thanks for signing up!</p>
      <p class="font-semibold mt-5">Please note that the transaction coordinator build is for a USA audience only.</p>
      <p class="text-lg mt-2">We will need the following information before we can start the transaction coordinator build.</p>
      <!-- Follow Up Boss Credentials -->
      <section>
        <p class="font-semibold text-lg">
          Follow Up Boss Credentials
          <span class="text-base font-light text-gray-700">(Owner Account)</span>
        </p>
        <div class="inline-block bg-gray-100 p-5 rounded pt-3">
          <VTextField :v="v$.formModel.fubUsername" placeholder="Username" autocomplete="off" />
          <VTextField :v="v$.formModel.fubPassword" placeholder="Password" autocomplete="off" />
        </div>
      </section>
      <!-- Company -->
      <section>
        <p class="font-semibold text-lg">Company Name</p>
        <div class="inline-block bg-gray-100 p-5 rounded pt-3">
          <VTextField :v="v$.formModel.company" placeholder="Jane Doe Group - KW Realty" class="text-sm" />
        </div>
      </section>
      <!-- Business Address -->
      <section>
        <p class="font-semibold text-lg">Business Address</p>
        <div class="inline-block bg-gray-100 p-5 rounded pt-3">
          <VTextField :v="v$.formModel.businessAddress.addressLine1" placeholder="Address Line 1" />
          <VTextField :v="v$.formModel.businessAddress.addressLine2" placeholder="Address Line 2" />
          <CountrySelect v-model="formModel.businessAddress.country"
            :errorMessage="v$.formModel.businessAddress.country.$error ? v$.formModel.businessAddress.country.$errors[0].$message : ''" />
          <VTextField :v="v$.formModel.businessAddress.city" placeholder="City" />
          <StateSelect v-model="formModel.businessAddress.state"
            :errorMessage="v$.formModel.businessAddress.state.$error ? v$.formModel.businessAddress.state.$errors[0].$message : ''"
            :showProvinces="formModel.businessAddress.country === 'Canada'" />
          <VTextField :v="v$.formModel.businessAddress.zip"
            :placeholder="formModel.businessAddress.country === 'Canada' ? 'Postal Code' : 'Zip Code'" />
        </div>
      </section>
      <!-- Servicing Multiple Markets -->
      <section>
        <p class="font-semibold text-lg">Do you service more than one market (multiple states)?</p>
        <div class="field" :class="{ 'field-block-error': v$.formModel.multiMarketServicing.$error }">
          <p v-if="v$.formModel.multiMarketServicing.$error" class="field-error text-xs text-red-500">
            {{ v$.formModel.multiMarketServicing.$errors[0].$message }}
          </p>
          <input type="radio" value="Yes" v-model="formModel.multiMarketServicing" />
          <label>Yes</label>
          <br />
          <input type="radio" value="No" v-model="formModel.multiMarketServicing" />
          <label>No</label>
        </div>
      </section>
      <!-- ETA State -->
      <section>
        <p class="font-semibold text-lg">Who handles closing?</p>
        <div class="field" :class="{ 'field-block-error': v$.formModel.closing.$error }">
          <p v-if="v$.formModel.closing.$error" class="field-error text-xs text-red-500">
            {{ v$.formModel.closing.$errors[0].$message }}
          </p>
          <input type="radio" value="Escrow" v-model="formModel.closing" />
          <label>Escrow</label>
          <br />
          <input type="radio" value="Title" v-model="formModel.closing" />
          <label>Title</label>
          <br />
          <input type="radio" value="Attorney" v-model="formModel.closing" />
          <label>Attorney</label>
        </div>
      </section>
      <!-- Deposit Holding -->
      <section>
        <p class="font-semibold text-lg">Where are buyer deposits held?</p>
        <div class="inline-block bg-gray-100 p-5 rounded pt-3">
          <DepositSelect v-model="formModel.depositHolding"
            :errorMessage="v$.formModel.depositHolding.$error ? v$.formModel.depositHolding.$errors[0].$message : ''" />
        </div>
      </section>
      <section v-if="formModel.depositHolding === 'Other (specify)'">
        <p class="font-semibold text-lg">Specify buyer deposit holding for your state</p>
        <div class="inline-block bg-gray-100 p-5 rounded pt-3">
          <VTextField :v="v$.formModel.depositHoldingSpecify" />
        </div>
      </section>
      <!-- Team of Agents -->
      <section>
        <p class="font-semibold text-lg">Do you have a team of agents that work under you?</p>
        <div class="field" :class="{ 'field-block-error': v$.formModel.teamOfAgents.$error }">
          <p v-if="v$.formModel.teamOfAgents.$error" class="field-error text-xs text-red-500">
            {{ v$.formModel.teamOfAgents.$errors[0].$message }}
          </p>
          <input type="radio" value="Yes" v-model="formModel.teamOfAgents" />
          <label>Yes</label>
          <br />
          <input type="radio" value="No" v-model="formModel.teamOfAgents" />
          <label>No</label>
        </div>
      </section>
      <!-- Team Leader -->
      <section v-if="formModel.teamOfAgents === 'Yes'">
        <p class="font-semibold text-lg">Congrats emails should send from (specify team leader below)</p>
        <div class="inline-block bg-gray-100 p-5 rounded pt-3">
          <VTextField :v="v$.formModel.teamLeader" placeholder="team leader" />
        </div>
      </section>
      <!-- Transaction Coordinator -->
      <section>
        <p class="font-semibold text-lg">Do you have a transaction coordinator?</p>
        <div class="field" :class="{ 'field-block-error': v$.formModel.transactionCoordinator.$error }">
          <p v-if="v$.formModel.transactionCoordinator.$error" class="field-error text-xs text-red-500">
            {{ v$.formModel.transactionCoordinator.$errors[0].$message }}
          </p>
          <input type="radio" value="Yes" v-model="formModel.transactionCoordinator" />
          <label>Yes</label>
          <br />
          <input type="radio" value="No" v-model="formModel.transactionCoordinator" />
          <label>No</label>
        </div>
      </section>
      <!-- TC List -->
      <section v-if="formModel.transactionCoordinator === 'Yes'">
        <p class="font-semibold text-lg">List transaction coordinators below</p>
        <div v-for="tc in formModel.tcList" :key="tc.name" class="mt-2 flex items-center bg-white p-5 shadow max-w-3xl text-sm">
          <div class="flex-grow"><strong>{{ tc.name }}</strong> works with <strong>{{ tc.type }}</strong></div>
          <font-awesome-icon icon="fa-solid fa-trash" class="ml-5 text-red-500 cursor-pointer" @click="deleteTC(tc.name)" />
        </div>
        <a @click="stageTC" class="
          text-site-purple
          font-semibold
          mt-1
          inline-block
          underline
          cursor-pointer
        ">
          <font-awesome-icon icon="fa-solid fa-plus-circle" />
          Add Transaction Coordinator
        </a>
        <Modal v-if="showTCModal" size="sm" @close="showTCModal = false">
          <div class="flex flex-col items-center">
            <p class="text-center text-lg font-semibold">Tell us about the transaction coordinator</p>
            <input type="text" v-model="stagedTC.name" placeholder="Name" class="mt-5" />
            <p class="mt-5">works with...</p>
            <select v-model="stagedTC.type" class="mt-5" style="appearance: revert;">
              <option disabled value="">select work type</option>
              <option>Listing Coordination</option>
              <option>Selling Transactions</option>
              <option>Buying Transactions</option>
              <option>All - Listing Coordination + Selling/Buying Transactions</option>
            </select>
            <button class="button mt-5" @click="addTC" :disabled="stagedTC.name === '' || stagedTC.type === ''">Add</button>
          </div>
        </Modal>
      </section>
      <!-- ISA Compensation -->
      <section v-if="formModel.transactionCoordinator === 'Yes'">
        <p class="font-semibold text-lg">Does your team consist of ISAs that receive compensation from closed transactions?</p>
        <div class="field" :class="{ 'field-block-error': v$.formModel.isaCompensation.$error }">
          <p v-if="v$.formModel.isaCompensation.$error" class="field-error text-xs text-red-500">
            {{ v$.formModel.isaCompensation.$errors[0].$message }}
          </p>
          <input type="radio" value="Yes" v-model="formModel.isaCompensation" />
          <label>Yes</label>
          <br />
          <input type="radio" value="No" v-model="formModel.isaCompensation" />
          <label>No</label>
        </div>
      </section>
      <!-- Setup activation notice -->
      <div class="p-10 mt-10 border bg-gray-100">
        <h3 class="text-lg font-semibold">Activating your new setup</h3>
        <p class="mt-5">After the setup of your new action plans and automations is complete, we will notify you via
          email. At this stage, you can either approve immediate activation or opt for a review period to evaluate the
          setup before it goes live.</p>
      </div>
      <!-- Condition Agreement -->
      <section>
        <div class="field" :class="{ 'field-block-error': v$.formModel.agreeToConditions.$error }">
          <p v-if="v$.formModel.agreeToConditions.$error" class="field-error text-xs text-red-500">
            {{ v$.formModel.agreeToConditions.$errors[0].$message }}
          </p>
          <input type="checkbox" v-model="formModel.agreeToConditions" true-value="agree" false-value="" />
          <label class="ml-2 font-medium">I agree to
            <a @click="showConditionAgreement = true" class="link">these conditions</a></label>
        </div>
        <ConditionAgreementModal type="tc-assist" v-if="showConditionAgreement" @close="showConditionAgreement = false" />
      </section>
      <button @click="submit" :disabled="!canSubmit" class="button mt-5">Submit</button>
      <p v-if="v$.$error" class="mt-2 text-red-500">
        Oops... There is an issue with the form. Check fields for errors.
      </p>
      <SubmittingModal
        v-if="isSubmitting"
        @close="(isSubmitting = false)"
        :loading="loading"
        :errorMessage="error"
        :showNextSteps="true"
      >
        <template #success>
          <div>
            <p class="text-3xl text-site-purple font-semibold">Sign up complete!</p>
            <p class="text-xl pt-5">Your information has been submitted successfully!</p>
          </div>
        </template>
      </SubmittingModal>
    </div>
  </StandardPageLayout>
</template>
<script>
import { required, requiredIf, formValidationMixin } from '@/mixins/formValidateMixin'
import { SETUP_TYPE } from '../../../constants';
import DepositSelect from './DepositSelect.vue';
import _pick from 'lodash/pick'

class TransactionCoordinator {
  constructor({ name, type }) {
    this.name = name
    this.type = type
  }
}

export default {
  mixins: [formValidationMixin],
  components: { DepositSelect },
  data() {
    return {
      loading: false,
      error: "",
      person: {},
      personError: false,
      formModel: {
        fubUsername: "",
        fubPassword: "",
        company: "",
        businessAddress: {
          addressLine1: "",
          addressLine2: "",
          country: "",
          city: "",
          state: "",
          zip: "",
        },
        multiMarketServicing: "",
        closing: "",
        depositHolding: "",
        depositHoldingSpecify: "",
        teamOfAgents: "",
        teamLeader: "",
        transactionCoordinator: "",
        tcList: [],
        isaCompensation: "",
        agreeToConditions: "",
      },
      stagedTC: null,
      isSubmitting: false,
      showTCModal: false,
      showConditionAgreement: false,
    }
  },
  validations() {
    return {
      formModel: {
        fubUsername: { required },
        fubPassword: { required },
        company: { required },
        businessAddress: {
          addressLine1: { required },
          addressLine2: {},
          country: { required },
          city: { required },
          state: { required },
          zip: { required },
        },
        multiMarketServicing: { required },
        closing: { required },
        depositHolding: { required },
        depositHoldingSpecify: { required: requiredIf(this.formModel.depositHolding === 'Other (specify)') },
        teamOfAgents: { required },
        teamLeader: { required: requiredIf(this.formModel.teamOfAgents === 'Yes') },
        transactionCoordinator: { required },
        isaCompensation: { required: requiredIf(this.formModel.transactionCoordinator === 'Yes') },
        agreeToConditions: { required },
      }
    }
  },
  async created() {
    await this.$service.getPerson(this.keeId).then((person) => this.person = person).catch(() => this.personError = true)
  },
  computed: {
    keeId() {
      return this.$route.params.keeId
    },
    setupType() {
      return SETUP_TYPE.TC_ASSIST
    },
    canSubmit() {
      return !this.isSubmitting;
    },
    personLoaded() {
      return !!this.person.meta
    }
  },
  methods: {
    stageTC() {
      this.stagedTC = new TransactionCoordinator({ name: "", type: "" })
      this.showTCModal = true
    },
    addTC() {
      this.formModel.tcList.push(this.stagedTC)
      this.showTCModal = false
    },
    deleteTC(tcName) {
      this.formModel.tcList = this.formModel.tcList.filter(({ name }) => name !== tcName)
    },
    buildNotes() {
      return _pick(this.formModel, [
        'company',
        'businessAddress',
        'multiMarketServicing',
        'closing',
        'depositHolding',
        'depositHoldingSpecify',
        'teamOfAgents',
        'teamLeader',
        'transactionCoordinator',
        'tcList',
        'isaCompensation'
      ])
    },
    async submit() {
      if (!(await this.v$.$validate())) return

      try {
        const notes = this.buildNotes()
        this.error = false
        this.isSubmitting = true
        this.loading = true

        await this.$service.manualSetupData({
          keeId: this.keeId,
          fubPassword: this.formModel.fubPassword,
          fubUsername: this.formModel.fubUsername,
          notes,
          meta: {
            setupType: this.setupType,
            customStages: this.customizeStages
          },
          businessAddress: this.formModel.businessAddress
        })
        this.loading = false
      } catch (e) {
        this.error = this.$service.getErrorMessage(e)
      }
    },
  }
}
</script>
<style scoped>
section {
  @apply mt-10;
}

label {
  @apply mr-2;
}

.field {
  @apply mt-2;
}

input[type="text"],
input[type="password"],
select {
  @apply border rounded py-1 px-2 w-64;
}

input[type="radio"] {
  @apply mr-2;
}

.field-block-error {
  @apply inline-block border border-red-500 p-2 rounded;
}
</style>