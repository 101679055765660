<template>
  <LandingPageLayout @handleCTA="openSignUp" :navItems="navItems">
    <Hero ref="hero" :setupType="setupType" :affiliate="affiliate" :calendarUrl="calendarUrl" />
    <Benefits />
    <PainPoints />
    <Testimonials />
    <WhatsIncluded />
    <FAQ :faqList="faqList" />
    <Pricing @openSignUp="openSignUp" :basePrice="basePrice" :addOns="addOns" />
    <OtherServices class="md:hidden" />
    <Schedule @openSignUp="openSignUp" :calendarUrl="calendarUrl" />
  </LandingPageLayout>
</template>
<script>
import LandingPageLayout from '../../layouts/LandingPageLayout'
import Hero from '../../common/landing/Hero.vue'
import Benefits from './Benefits.vue'
import PainPoints from './PainPoints.vue'
import Testimonials from './Testimonials.vue'
import WhatsIncluded from './WhatsIncluded.vue'
import FAQ from '../../common/landing/FAQ.vue'
import Pricing from '../../common/landing/Pricing'
import Schedule from '../../common/landing/Schedule'
import OtherServices from './OtherServices.vue'
import navItems from './navItems'
import { mapGetters } from 'vuex'
import { SETUP_TYPE, CALENDLY_CALENDARS, CALENDLY_UTM } from '../../../constants.js'
import { getPricing } from '@/pricing'
import { buildCalendarUrl } from '../../../utils'

export default {
  components: {
    LandingPageLayout,
    Hero,
    Benefits,
    PainPoints,
    Testimonials,
    WhatsIncluded,
    FAQ,
    Pricing,
    Schedule,
    OtherServices,
  },
  props: {
    affiliate: {
      type: String,
      required: false,
      default: ''
    }
  },
  mounted() {
    if (this.directSignUp) {
      this.openSignUp()
    }
  },
  computed: {
    ...mapGetters(['faqList']),
    setupType() {
      return SETUP_TYPE.FUB_AGENT_REVAMP
    },
    navItems() {
      return navItems
    },
    pricing() {
      return getPricing(SETUP_TYPE.FUB_AGENT_REVAMP)
    },
    basePrice() {
      return this.pricing.BASE.toLocaleString('en')
    },
    addOns() {
      return [
        {
          title: 'Spanish Action Plans',
          price: this.pricing.SPANISH_ACTION_PLANS.toLocaleString('en'),
        },
        {
          title: 'Lender Action Plans',
          price: this.pricing.LENDER_ADD_ON.toLocaleString('en'),
        },
        {
          title: 'SMS Drips',
          subtext: '* Must have Leadngage, Texting Betty, or StreetText',
          price: this.pricing.SMS_DRIPS.toLocaleString('en'),
        }
      ]
    },
    calendarUrl() {
      return buildCalendarUrl({
        calendar: CALENDLY_CALENDARS.DISCOVERY,
        utm: this.affiliate ? CALENDLY_UTM.AFFILIATE_DISCOVERY(this.affiliate) : CALENDLY_UTM.DISCOVERY
      })
    },
  },
  methods: {
    openSignUp() {
      this.$refs.hero.openSignUp()
    }
  },
}
</script>