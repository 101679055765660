export default [
  {
    text: 'Testimonials',
    scrollId: 'testimonials',
    showOnXLOnly: true
  },
  {
    text: "What's Included",
    scrollId: 'service'
  },
  {
    text: "Pricing",
    scrollId: 'pricing'
  },
  {
    text: 'Audit',
    to: '/audit'
  },
  {
    text: 'Guides',
    subNavItems: [
      {
        text: 'Solo Agent Guide',
        to: '/solo'
      },
      {
        text: 'Team Lead Guide',
        to: '/team-lead'
      }
    ]
  }
]