<template>
  <div class="mt-2">
    <textarea
      :value="v.$model"
      class="border rounded py-1 px-2 w-96 h-48"
      :class="{ 'border-red-500': !!errorMessage }"
      @input="handleInput"
      :placeholder="placeholder"
    ></textarea>
    <p v-if="errorMessage" class="text-xs text-red-500">{{ errorMessage }}</p>
  </div>
</template>
<script>
export default {
  props: ["v", "placeholder"],
  computed: {
    errorMessage() {
      return this.v.$error && this.v.$errors[0].$message
    }
  },
  methods: {
    handleInput(e) {
      this.v.$model = e.target.value
    }
  },
};
</script>