<template>
  <section class="py-20 px-6 text-white" id="pricing">
    <div class="container mx-auto">
      <h3 class="text-center text-4xl font-bold">Curious about pricing?</h3>
      <div class="mt-20 bg-gray-100 text-gray-700 p-10 lg:p-20 rounded-xl border-8 border-kts-gold">
        <p class="text-center text-3xl font-semibold">TC Assist</p>
        <p class="text-center text-lg">Transaction Coordinator Follow Up Boss Build</p>
        <p class="text-center text-5xl mt-10 font-semibold">${{basePrice}} <span class="text-xl">USD</span></p>
        <hr />
        <p class="text-center text-2xl">Just some basic information is needed to get the ball rolling!</p>
        <div class="text-center mt-10">
          <a class="bg-kts-gold text-black uppercase font-semibold py-4 px-10 text-lg inline-block cursor-pointer tracking-wider hover-bright" @click="$emit('openSignUp')">Get Started</a>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
export default {
  props: ['basePrice']
}
</script>
<style scoped>
hr {
  @apply border-gray-300 my-14;
}
</style>