<template>
  <div v-if="attemptedAffiliate" class="h-screen flex justify-center items-center">
    <div class="text-center">
      <h1 class="text-8xl">404</h1>
      <p class="text-2xl">Page not found.</p>
      <div class="mt-5">
        <router-link to="/" class="link text-lg">Take me back</router-link>
      </div>
    </div>
  </div>
</template>
<script>
import Home from '../home'
export default {
  data() {
    return {
      attemptedAffiliate: false
    }
  },
  async created() {
    try {
      const routePathArray = this.$route.path.slice(1).split('/')
      if (routePathArray.length === 1) {
        const slug = routePathArray[0].toLowerCase()
        const affiliate = await this.$service.getAffiliateBySlug(slug)
        this.$router.addRoutes([ { path: `/${affiliate.slug}`, component: Home, props: { affiliate: affiliate.name } } ])
        this.$router.replace(`/${affiliate.slug}`)
      } else {
        this.attemptedAffiliate = true
      }
    } catch  {
      this.attemptedAffiliate = true
      console.error('Could not retrieve affiliates')
    }
  }
}
</script>