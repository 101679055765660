<template>
  <StandardPageLayout>
    <!-- <p>{{ person.meta.depositAmount }}</p> -->
    <div v-if="personError">
      <p class="text-center mt-10 text-xl">Could not find account.</p>
    </div>
    <div v-else-if="personLoaded" class="container mx-auto p-10">
      <p class="text-2xl font-semibold text-site-purple">Thanks for signing up!</p>
      <p class="text-lg mt-5">
        We will need the following information before we can start revamping your
        FUB.
      </p>
      <!-- Follow Up Boss Credentials -->
      <section>
        <p class="font-semibold text-lg">
          Follow Up Boss Credentials
          <span class="text-base font-light text-gray-700">(Owner Account)</span>
        </p>
        <div class="inline-block bg-gray-100 p-5 rounded pt-3">
          <VTextField :v="v$.formModel.fubUsername" placeholder="Username" autocomplete="off" />
          <VTextField :v="v$.formModel.fubPassword" placeholder="Password" autocomplete="off" />
        </div>
      </section>
      <!-- Website URL(s) -->
      <section class="inline-block">
        <p class="font-semibold text-lg -mb-2">Website URL</p>
        <div class="field bg-gray-100 p-5" v-for="(website, idx) in formModel.websites" :key="`website_${idx}`"
          :class="{ 'mt-5': idx !== 0 }">
          <input type="text" v-model="website.url" placeholder="http://mywebsite.com" />
          <span v-if="idx !== 0" class="cursor-pointer ml-2" @click="removeWebsite(idx)">
            <font-awesome-icon icon="fa-solid fa-times" />
          </span>
        </div>
        <p class="mt-5 font-semibold text-sm">Have multiple websites?</p>
        <a @click="addWebsite" class="
          text-site-purple
          font-semibold
          mt-1
          inline-block
          underline
          cursor-pointer
        ">
          <font-awesome-icon icon="fa-solid fa-plus-circle" />
          Add Website
        </a>
      </section>
      <!-- Online Reviews -->
      <section>
        <p class="font-semibold text-lg">Online Reviews <span class="text-base font-light text-gray-700">(Please provide
            any URLs to your online reviews i.e Google, Zillow, Facebook etc.)</span></p>
        <!-- <p class="text-base font-light text-gray-700">This is so you can ask for reviews in the post closing drips and build trust with potential clients in the nuture drips</p> -->
        <div class="inline-block">
          <div class="field bg-gray-100 p-5" v-for="(onlineReview, idx) in formModel.onlineReviews"
            :key="`online_review_${idx}`" :class="{ 'mt-5': idx !== 0 }">
            <input type="text" v-model="onlineReview.url" placeholder="http://review-url.com" />
            <span v-if="idx !== 0" class="cursor-pointer ml-2" @click="removeOnlineReview(idx)">
              <font-awesome-icon icon="fa-solid fa-times" />
            </span>
          </div>
        </div>
        <p class="mt-5 font-semibold text-sm">Have more online review URLs?</p>
        <a @click="addOnlineReview" class="
          text-site-purple
          font-semibold
          mt-1
          inline-block
          underline
          cursor-pointer
        ">
          <font-awesome-icon icon="fa-solid fa-plus-circle" />
          Add Online Review URL
        </a>
      </section>
      <!-- YouTube Channel -->
      <section>
        <p class="font-semibold text-lg">YouTube Channel</p>
        <div class="inline-block bg-gray-100 p-5 rounded pt-3">
          <div class="field">
            <VTextField :v="v$.formModel.youtube" placeholder="YouTube Channel URL" />
          </div>
        </div>
      </section>
      <!-- Brokerage -->
      <section>
        <p class="font-semibold text-lg">Brokerage</p>
        <div class="inline-block bg-gray-100 p-5 rounded pt-3">
          <VTextField :v="v$.formModel.brokerage" placeholder="i.e Keller Williams Realty" />
        </div>
      </section>
      <!-- Business Address -->
      <section>
        <p class="font-semibold text-lg">Business Address</p>
        <div class="inline-block bg-gray-100 p-5 rounded pt-3">
          <VTextField :v="v$.formModel.businessAddress.addressLine1" placeholder="Address Line 1" />
          <VTextField :v="v$.formModel.businessAddress.addressLine2" placeholder="Address Line 2" />
          <CountrySelect
            v-model="formModel.businessAddress.country"
            :errorMessage="v$.formModel.businessAddress.country.$error ? v$.formModel.businessAddress.country.$errors[0].$message : ''"
          />
          <VTextField :v="v$.formModel.businessAddress.city" placeholder="City" />
          <StateSelect
            v-model="formModel.businessAddress.state"
            :errorMessage="v$.formModel.businessAddress.state.$error ? v$.formModel.businessAddress.state.$errors[0].$message : ''"
            :showProvinces="formModel.businessAddress.country === 'Canada'"
          />
          <VTextField :v="v$.formModel.businessAddress.zip" :placeholder="formModel.businessAddress.country === 'Canada' ? 'Postal Code' : 'Zip Code'" />
        </div>
      </section>
      <!-- Servicing Multiple Markets -->
      <section>
        <p class="font-semibold text-lg">Do you service more than one market (multiple states/provinces, regions)?</p>
        <div class="field" :class="{
          'field-block-error': v$.formModel.multiMarketServicing.$error,
        }">
          <p v-if="v$.formModel.multiMarketServicing.$error" class="field-error text-xs text-red-500">
            {{ v$.formModel.multiMarketServicing.$errors[0].$message }}
          </p>
          <input type="radio" value="Yes" v-model="formModel.multiMarketServicing" />
          <label>Yes</label>
          <br />
          <input type="radio" value="No" v-model="formModel.multiMarketServicing" />
          <label>No</label>
        </div>
      </section>
      <!-- RealScout -->
      <section>
        <p class="font-semibold text-lg">Do you use RealScout?</p>
        <div class="field" :class="{
          'field-block-error': v$.formModel.usesRealScout.$error,
        }">
          <p v-if="v$.formModel.usesRealScout.$error" class="field-error text-xs text-red-500">
            {{ v$.formModel.usesRealScout.$errors[0].$message }}
          </p>
          <input type="radio" value="Yes" v-model="formModel.usesRealScout" />
          <label>Yes</label>
          <br />
          <input type="radio" value="No" v-model="formModel.usesRealScout" />
          <label>No</label>
        </div>
      </section>
      <!-- Fello -->
      <section>
        <p class="font-semibold text-lg">Do you use Fello?</p>
        <div class="field" :class="{
          'field-block-error': v$.formModel.usesFello.$error,
        }">
          <p v-if="v$.formModel.usesFello.$error" class="field-error text-xs text-red-500">
            {{ v$.formModel.usesFello.$errors[0].$message }}
          </p>
          <input type="radio" value="Yes" v-model="formModel.usesFello" />
          <label>Yes</label>
          <br />
          <input type="radio" value="No" v-model="formModel.usesFello" />
          <label>No</label>
        </div>
      </section>
      <!-- Conversion Monster -->
      <section>
        <p class="font-semibold text-lg">Do you use Conversion Monster?</p>
        <div class="field" :class="{
          'field-block-error': v$.formModel.usesConversionMonster.$error,
        }">
          <p v-if="v$.formModel.usesConversionMonster.$error" class="field-error text-xs text-red-500">
            {{ v$.formModel.usesConversionMonster.$errors[0].$message }}
          </p>
          <input type="radio" value="Yes" v-model="formModel.usesConversionMonster" />
          <label>Yes</label>
          <br />
          <input type="radio" value="No" v-model="formModel.usesConversionMonster" />
          <label>No</label>
        </div>
      </section>
      <!-- BombBomb -->
      <section>
        <p class="font-semibold text-lg">Do you have BombBomb videos?</p>
        <div class="field" :class="{ 'field-block-error': v$.formModel.hasBombBombVideos.$error }">
          <p v-if="v$.formModel.hasBombBombVideos.$error" class="field-error text-xs text-red-500">
            {{ v$.formModel.hasBombBombVideos.$errors[0].$message }}
          </p>
          <input type="radio" value="Yes" v-model="formModel.hasBombBombVideos" />
          <label>Yes</label>
          <br />
          <input type="radio" value="No" v-model="formModel.hasBombBombVideos" />
          <label>No</label>
        </div>
      </section>
      <section v-if="formModel.hasBombBombVideos === 'Yes'">
        <p class="font-semibold text-lg">BombBomb Credentials</p>
        <div class="inline-block bg-gray-100 p-5 rounded pt-3">
          <VTextField :v="v$.formModel.bombbombUsername" placeholder="Username" autocomplete="off" />
          <VTextField :v="v$.formModel.bombbombPassword" placeholder="Password" autocomplete="off" />
        </div>
      </section>
      <!-- Ylopo Users -->
      <section>
        <p class="font-semibold text-lg">Are you a Ylopo user?</p>
        <div class="field" :class="{ 'field-block-error': v$.formModel.ylopoUser.$error }">
          <p v-if="v$.formModel.ylopoUser.$error" class="field-error text-xs text-red-500">
            {{ v$.formModel.ylopoUser.$errors[0].$message }}
          </p>
          <input type="radio" id="ylopo-user-yes" value="Yes" v-model="formModel.ylopoUser" />
          <label for="ylopo-user-yes">Yes</label>
          <br />
          <input type="radio" id="ylopo-user-no" value="No" v-model="formModel.ylopoUser" />
          <label for="ylopo-user-no">No</label>
        </div>
      </section>
      <!-- Ylopo Users - Yes - Search Site Live -->
      <div v-if="formModel.ylopoUser === 'Yes'">
        <section>
          <p class="font-semibold text-lg">Is your search site live?</p>
          <div class="field" :class="{
            'field-block-error': v$.formModel.ylopoUserSearchSiteLive.$error,
          }">
            <p v-if="v$.formModel.ylopoUserSearchSiteLive.$error" class="field-error text-xs text-red-500">
              {{ v$.formModel.ylopoUserSearchSiteLive.$errors[0].$message }}
            </p>
            <input type="radio" id="ylopo-search-site-live-yes" value="Yes"
              v-model="formModel.ylopoUserSearchSiteLive" />
            <label for="ylopo-search-site-live-yes">Yes</label>
            <br />
            <input type="radio" id="ylopo-search-site-live-no" value="No" v-model="formModel.ylopoUserSearchSiteLive" />
            <label for="ylopo-search-site-live-no">No</label>
          </div>
        </section>
        <section>
          <p class="font-semibold text-lg">Is your branded website live?</p>
          <div class="field" :class="{
            'field-block-error': v$.formModel.ylopoUserBrandedSiteLive.$error,
          }">
            <p v-if="v$.formModel.ylopoUserBrandedSiteLive.$error" class="field-error text-xs text-red-500">
              {{ v$.formModel.ylopoUserBrandedSiteLive.$errors[0].$message }}
            </p>
            <input type="radio" id="ylopo-branded-site-live-yes" value="Yes"
              v-model="formModel.ylopoUserBrandedSiteLive" />
            <label for="ylopo-branded-site-live-yes">Yes</label>
            <br />
            <input type="radio" id="ylopo-branded-site-live-no" value="No"
              v-model="formModel.ylopoUserBrandedSiteLive" />
            <label for="ylopo-branded-site-live-no">No</label>
            <br />
            <input type="radio" id="ylopo-branded-site-live-not-using" value="Not Using"
              v-model="formModel.ylopoUserBrandedSiteLive" />
            <label for="ylopo-branded-site-live-not-using">Not using branded site</label>
          </div>
        </section>
        <section v-if="formModel.ylopoUserBrandedSiteLive === 'Yes'">
          <p class="font-semibold text-lg">Branded Website URL</p>
          <div class="inline-block bg-gray-100 p-5 rounded pt-3">
            <VTextField :v="v$.formModel.ylopoUserBrandedSiteUrl" placeholder="URL" autocomplete="off" />
          </div>
        </section>
      </div>
      <!-- Non Ylopo Users -->
      <section v-if="nonYlopoUser">
        <p class="font-semibold text-lg">
          Is the Follow Up Boss Pixel installed on your website?
        </p>
        <div class="field" :class="{ 'field-block-error': v$.formModel.fubPixelInstalled.$error }">
          <p v-if="v$.formModel.fubPixelInstalled.$error" class="field-error text-xs text-red-500">
            {{ v$.formModel.fubPixelInstalled.$errors[0].$message }}
          </p>
          <input type="radio" id="fub-pixel-installed-yes" value="Yes" v-model="formModel.fubPixelInstalled" />
          <label for="fub-pixel-installed-yes">Yes</label>
          <br />
          <input type="radio" id="fub-pixel-installed-no" value="No" v-model="formModel.fubPixelInstalled" />
          <label for="fub-pixel-installed-no">No</label>
          <br />
          <input type="radio" id="fub-pixel-installed-no-not-compatible" value="Not Compatible"
            v-model="formModel.fubPixelInstalled" />
          <label for="fub-pixel-installed-no-not-compatible">No and I know my website isn't compatible with the FUB
            Pixel</label>
        </div>
      </section>
      <section v-if="nonYlopoUser && formModel.fubPixelInstalled === 'No'" class="inline-block">
        <p class="font-semibold text-lg">Courtesy service for installing the Follow Up Boss Pixel</p>
        <p class="text-sm text-gray-700">If you have a website backend and would like us to attempt the installation of the Follow Up Boss pixel for you, please provide your site's credentials below.<br><br><strong>Please note</strong> we offer this install service as a courtesy only and is not an objective step in the FUB revamp service we provide.<br><br><strong>Please note</strong> not every website is compatible with the Follow Up Boss Pixel and some site providers even reject the install of 3rd party scripts. Often we don't know compatibility issues until we attempt to install.<br><br>If you prefer to install the FUB pixel yourself you can refer to <a class="link" href="https://help.followupboss.com/hc/en-us/sections/1500001949321-Follow-Up-Boss-Pixel" target="_blank">Follow Up Boss Pixel documentation</a></p>
        <div v-for="(websiteBackend, idx) in formModel.websiteBackends" :key="`website_backend_${idx}`"
          class="bg-gray-100 p-5 pt-3 mt-5">
          <div v-if="idx !== 0" class="flex justify-end">
            <span class="cursor-pointer" @click="removeBackend(idx)">
              <font-awesome-icon icon="fa-solid fa-times" /> Remove
            </span>
          </div>
          <div class="field">
            <input type="text" v-model="websiteBackend.url" placeholder="Backend URL" />
          </div>
          <div class="field">
            <input type="text" v-model="websiteBackend.username" placeholder="Username" />
          </div>
          <div class="field">
            <input type="text" v-model="websiteBackend.password" placeholder="Password" />
          </div>
        </div>
        <p class="mt-5 font-semibold text-sm">
          Need the FUB pixel added to multiple websites?
        </p>
        <a @click="addBackend" class="
          text-site-purple
          font-semibold
          mt-1
          inline-block
          underline
          cursor-pointer
        ">
          <font-awesome-icon icon="fa-solid fa-plus-circle" />
          Add Website
        </a>
      </section>
      <!-- Lender Action Plans -->
      <section v-if="includeLenderAddOn">
        <p class="font-semibold text-lg">Do all lenders in your account work for the same mortgage company?</p>
        <div class="field" :class="{ 'field-block-error': v$.formModel.singleMortgageCompany.$error }">
          <p v-if="v$.formModel.singleMortgageCompany.$error" class="field-error text-xs text-red-500">
            {{ v$.formModel.singleMortgageCompany.$errors[0].$message }}
          </p>
          <input type="radio" value="Yes" v-model="formModel.singleMortgageCompany" />
          <label>Yes</label>
          <br />
          <input type="radio" value="No" v-model="formModel.singleMortgageCompany" />
          <label>No</label>
        </div>
      </section>
      <section v-if="formModel.singleMortgageCompany === 'Yes'">
        <p class="font-semibold text-lg">Lender Website</p>
        <div class="inline-block bg-gray-100 p-5 rounded pt-3">
          <div class="field">
            <VTextField :v="v$.formModel.lenderWebsiteUrl" placeholder="Website URL" />
          </div>
        </div>
      </section>
      <section v-if="formModel.singleMortgageCompany === 'Yes'">
        <p class="font-semibold text-lg">Online Reviews for Lender <span class="text-base font-light text-gray-700">(Please provide
          any URLs to reviews i.e Google, Facebook etc. Comma-seperated values)</span></p>
        <div class="inline-block bg-gray-100 p-5 rounded pt-3">
          <div class="field">
            <VTextField :v="v$.formModel.lenderOnlineReviews" placeholder="URL" />
          </div>
        </div>
      </section>
      <!-- Setup activation notice -->
      <div class="p-10 mt-10 border bg-gray-100">
        <h3 class="text-lg font-semibold">Activating your new setup</h3>
        <p class="mt-5">After the setup of your new action plans and automations is complete, we will notify you via email. At this stage, you can either approve immediate activation or opt for a review period to evaluate the setup before it goes live.</p>
        <p class="mt-5"><strong>Please note:</strong> Balance payment for the setup is due within two weeks from the setup completion date, irrespective of whether you choose immediate activation or a review period.</p>
      </div>
      <section>
        <div class="field" :class="{ 'field-block-error': v$.formModel.agreeToConditions.$error }">
          <p v-if="v$.formModel.agreeToConditions.$error" class="field-error text-xs text-red-500">
            {{ v$.formModel.agreeToConditions.$errors[0].$message }}
          </p>
          <input type="checkbox" v-model="formModel.agreeToConditions" true-value="agree" false-value="" />
          <label class="ml-2 font-medium">I agree to
            <a @click="showConditionAgreement = true" class="link">these conditions</a> <span class="font-light">(click the link to view terms and conditions)</span></label>
        </div>
      </section>
      <button @click="submit" :disabled="!canSubmit" class="button mt-5">Submit</button>
      <p v-if="v$.$error" class="mt-2 text-red-500">
        Oops... There is an issue with the form. Check fields for errors.
      </p>
      <ConditionAgreementModal v-if="showConditionAgreement" @close="showConditionAgreement = false" />
      <SubmittingModal
        v-if="isSubmitting"
        @close="(isSubmitting = false)"
        :loading="loading"
        :errorMessage="error"
        :showNextSteps="true"
      >
        <template #success>
          <div>
            <p class="text-3xl text-site-purple font-semibold">Sign up complete!</p>
            <p class="text-xl pt-5">Your information has been submitted successfully</p>
          </div>
        </template>
        <template #promo>
          <hr class="my-10" />
          <p class="text-lg font-medium">💬 Looking for a texting drip solution?</p>
          <p class="mt-3">We have a partnership solution with each of the texting services linked below. Click the links to learn more about each solution.</p>
          <ul class="mt-5 text-lg font-semibold">
            <li>
              <a class="link" @click="openRouteInNewWindow('texting-betty')">Texting Betty</a>
            </li>
            <li class="mt-5">
              <a class="link" @click="openRouteInNewWindow('leadngage')">Leadngage</a>
            </li>
            <li class="mt-5">
              <a class="link" @click="openRouteInNewWindow('streettext')">StreetText</a>
            </li>
            <li class="mt-5">
              <a class="link" @click="openRouteInNewWindow('kts-ai')">KTS AI w/ Structurely </a>
            </li>
          </ul>
          <hr class="mt-10" />
        </template>
      </SubmittingModal>
    </div>
  </StandardPageLayout>
</template>
<script>
import { required, requiredIf, formValidationMixin } from '@/mixins/formValidateMixin'
import _pick from 'lodash/pick'
import _get from 'lodash/get'
import { SETUP_TYPE, STAGE_SETUP_TYPES } from '../../../constants';

const newWebsiteBackend = {
  url: "",
  username: "",
  password: "",
};
const newWebsite = {
  url: "",
};
const newOnlineReview = {
  url: ""
};
export default {
  mixins: [formValidationMixin],
  props: ["keeId"],
  data() {
    return {
      loading: false,
      error: '',
      person: {},
      personError: false,
      isSubmitting: false,
      showConditionAgreement: false,
      formModel: {
        fubUsername: "",
        fubPassword: "",
        websites: [{ ...newWebsite }],
        onlineReviews: [{ ...newOnlineReview }],
        youtube: "",
        brokerage: "",
        businessAddress: {
          addressLine1: "",
          addressLine2: "",
          country: "",
          city: "",
          state: "",
          zip: "",
        },
        singleMortgageCompany: "",
        lenderWebsiteUrl: "",
        lenderOnlineReviews: "",
        multiMarketServicing: "",
        usesConversionMonster: "",
        usesRealScout: "",
        usesFello: "",
        hasBombBombVideos: "",
        bombbombUsername: "",
        bombbombPassword: "",
        ylopoUser: "",
        ylopoUserSearchSiteLive: "",
        ylopoUserBrandedSiteLive: "",
        ylopoUserBrandedSiteUrl: "",
        fubPixelInstalled: "",
        websiteBackends: [{ ...newWebsiteBackend }],
        agreeToConditions: "",
      },
    };
  },
  validations() {
    return {
      formModel: {
        fubUsername: { required },
        fubPassword: { required },
        youtube: {},
        brokerage: { required },
        businessAddress: {
          addressLine1: { required },
          addressLine2: {},
          country: { required },
          city: { required },
          state: { required },
          zip: { required },
        },
        multiMarketServicing: { required },
        usesRealScout: { required },
        usesFello: { required },
        usesConversionMonster: { required },
        hasBombBombVideos: { required },
        bombbombUsername: { required: requiredIf(this.usesBombBomb) },
        bombbombPassword: { required: requiredIf(this.usesBombBomb) },
        ylopoUser: { required },
        ylopoUserSearchSiteLive: { required: requiredIf(this.ylopoUser) },
        ylopoUserBrandedSiteLive: { required: requiredIf(this.ylopoUser) },
        ylopoUserBrandedSiteUrl: { required: requiredIf(this.brandedSiteLive) },
        fubPixelInstalled: { required: requiredIf(this.nonYlopoUser) },
        agreeToConditions: { required },
        singleMortgageCompany: { required: requiredIf(this.includeLenderAddOn) },
        lenderWebsiteUrl: { required: requiredIf(this.formModel.singleMortgageCompany === 'Yes') },
        lenderOnlineReviews: {},
      },
    };
  },
  computed: {
    setupType() {
      return SETUP_TYPE.FUB_AGENT_REVAMP
    },
    canSubmit() {
      return !this.isSubmitting;
    },
    usesBombBomb() {
      return this.formModel.hasBombBombVideos === "Yes";
    },
    ylopoUser() {
      return this.formModel.ylopoUser === "Yes";
    },
    brandedSiteLive() {
      return (
        this.ylopoUser && this.formModel.ylopoUserBrandedSiteLive === "Yes"
      );
    },
    nonYlopoUser() {
      return this.formModel.ylopoUser === "No";
    },
    noFubPixel() {
      return this.formModel.fubPixelInstalled === "No";
    },
    includeLenderAddOn() {
      return !!_get(this.person, 'meta.lenderAddOn')
    },
    zillowStages() {
      return !!_get(this.person, 'meta.zillowStages')
    },
    customStages() {
      return !!_get(this.person, 'meta.customStages')
    },
    spanishActionPlans() {
      return !!_get(this.person, 'meta.spanishActionPlans')
    },
    customUserEnabling() {
      return !!_get(this.person, 'meta.customUserEnabling')
    },
    personLoaded() {
      return !!this.person.meta
    }
  },
  watch: {
    usesBombBomb(newVal, oldVal) {
      if (oldVal && !newVal) {
        this.formModel.bombbombUsername = "";
        this.formModel.bombbombPassword = "";
      }
    },
    ylopoUser(newVal, oldVal) {
      if (oldVal && !newVal) {
        this.formModel.ylopoUserSearchSiteLive = "";
        this.formModel.ylopoUserBrandedSiteLive = "";
        this.formModel.ylopoUserBrandedSiteUrl = "";
      }
    },
    brandedSiteLive(newVal, oldVal) {
      if (oldVal && !newVal) {
        this.formModel.ylopoUserBrandedSiteUrl = "";
      }
    },
    nonYlopoUser(newVal, oldVal) {
      if (oldVal && !newVal) {
        this.formModel.fubPixelInstalled = "";
        this.formModel.websiteBackends = [{ ...newWebsiteBackend }];
      }
    },
    noFubPixel(newVal, oldVal) {
      if (oldVal && !newVal) {
        this.formModel.websiteBackends = [{ ...newWebsiteBackend }];
      }
    },
  },
  async created() {
    try {
      this.person = await this.$service.getPerson(this.keeId)
    } catch (e) {
      this.personError = true
    }
  },
  methods: {
    openRouteInNewWindow(routeName) {
      window.open(this.$router.resolve({ name: routeName }).href, '_blank')
    },
    addWebsite() {
      this.formModel.websites.push({ ...newWebsite });
    },
    removeWebsite(idx) {
      this.formModel.websites.splice(idx, 1);
    },
    addOnlineReview() {
      this.formModel.onlineReviews.push({ ...newOnlineReview });
    },
    removeOnlineReview(idx) {
      this.formModel.onlineReviews.splice(idx, 1);
    },
    addBackend() {
      this.formModel.websiteBackends.push({ ...newWebsiteBackend });
    },
    removeBackend(idx) {
      this.formModel.websiteBackends.splice(idx, 1);
    },
    buildTags() {
      var tags = [];
      if (this.ylopoUser) {
        tags.push("ylopo");
      }
      return tags;
    },
    buildNotes() {
      let buildNotes = _pick(this.formModel, [
        'websites',
        'onlineReviews',
        'youtube',
        'brokerage',
        'businessAddress',
        'multiMarketServicing',
        'usesConversionMonster',
        'usesRealScout',
        'usesFello',
        'hasBombBombVideos',
        'bombbombUsername',
        'bombbombPassword',
        'ylopoUser',
        'ylopoUserSearchSiteLive',
        'ylopoUserBrandedSiteLive',
        'ylopoUserBrandedSiteUrl',
        'fubPixelInstalled',
        'websiteBackends',
        'singleMortgageCompany',
        'lenderWebsiteUrl',
        'lenderOnlineReviews',
      ])

      buildNotes.setupEnabledFor = this.customUserEnabling ? 'Only me or a limited set of users' : 'All users'
      buildNotes.spanishActionPlans = this.spanishActionPlans ? 'Yes' : 'No'
      buildNotes.lenderAddOn = this.includeLenderAddOn ? 'Yes' : 'No'
      buildNotes.stageSetup = this.customStages ? STAGE_SETUP_TYPES.CUSTOM : this.zillowStages ? STAGE_SETUP_TYPES.ZILLOW_TWO_WAY_SYNC : STAGE_SETUP_TYPES.DEFAULT

      return buildNotes
    },
    async submit() {
      if (!(await this.v$.$validate())) return
      
      try {
        this.error = ''
        this.loading = true;
        this.isSubmitting = true;
        const tags = this.buildTags();
        const notes = this.buildNotes();

        await this.$service.manualSetupData({
          keeId: this.keeId,
          fubPassword: this.formModel.fubPassword,
          fubUsername: this.formModel.fubUsername,
          notes,
          tags,
          businessAddress: this.formModel.businessAddress,
          meta: {
            setupType: this.setupType,
          }
        })

        this.loading = false
      } catch (e) {
        this.error = this.$service.getErrorMessage(e)
        this.loading = false
      }
    },
  },
};
</script>
<style scoped>
section {
  @apply mt-10;
}

label {
  @apply mr-2;
}

.field {
  @apply mt-2;
}

input[type="text"],
input[type="password"],
select {
  @apply border rounded py-1 px-2 w-64;
}

input[type="radio"] {
  @apply mr-2;
}

.field-block-error {
  @apply inline-block border border-red-500 p-2 rounded;
}
</style>
