<template>
  <LandingPageLayout @handleCTA="openSignUp" :navItems="navItems">
    <Hero ref="hero" :setupType="setupType" :calendarUrl="calendarUrl" />
    <Benefits />
    <PainPoints />
    <WhatsIncluded />
    <FAQ :faqList="transactionFaq"/>
    <Pricing @openSignUp="openSignUp" :basePrice="basePrice" :payInFull="true" />
    <Schedule @openSignUp="openSignUp" :calendarUrl="calendarUrl" />
  </LandingPageLayout>
</template>
<script>
import { mapGetters } from 'vuex'
import LandingPageLayout from '../../layouts/LandingPageLayout.vue'
import Hero from '../../common/landing/Hero'
import Benefits from './Benefits.vue'
import PainPoints from './PainPoints.vue'
import WhatsIncluded from './WhatsIncluded'
import FAQ from '../../common/landing/FAQ'
import Pricing from './Pricing.vue'
import Schedule from '../../common/landing/Schedule'
import navItems from './navItems'
import { SETUP_TYPE, CALENDLY_CALENDARS, CALENDLY_UTM } from '../../../constants'
import { buildCalendarUrl } from '../../../utils'
import { getPricing } from '@/pricing'
export default {
  components: { LandingPageLayout, Hero, Benefits, PainPoints, WhatsIncluded, FAQ, Pricing, Schedule },
  computed: {
    ...mapGetters(['transactionFaq']),
    setupType() {
      return SETUP_TYPE.TC_ASSIST
    },
    pricing() {
      return getPricing(SETUP_TYPE.TC_ASSIST)
    },
    basePrice() {
      return this.pricing.BASE.toLocaleString('en')
    },
    calendarUrl() {
      return buildCalendarUrl({
        calendar: CALENDLY_CALENDARS.TC_ASSIST_DISCOVERY,
        utm: CALENDLY_UTM.TC_ASSIST_DISCOVERY
      })
    },
    navItems() {
      return navItems
    },
  },
  methods: {
    openSignUp() {
      this.$refs.hero.openSignUp()
    }
  }
}
</script>