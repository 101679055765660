<template>
  <StandardPageLayout>
    <section>
      <p class="font-semibold text-lg">Client Info</p>
      <div class="inline-block bg-gray-100 p-5 rounded pt-3">
        <VTextField :v="v$.formModel.basicInfo.firstName" placeholder="First Name" />
        <VTextField :v="v$.formModel.basicInfo.lastName" placeholder="Last Name" />
        <VTextField :v="v$.formModel.basicInfo.email" placeholder="Email" />
        <VTextField :v="v$.formModel.basicInfo.phone" placeholder="Phone Number" />
      </div>
    </section>
    <!-- Stage setup -->
    <section>
      <p class="font-semibold text-lg">Setting up stages for the revamp</p>
      <div class="field" :class="{ 'field-block-error': v$.formModel.stageSetup.$error }">
        <p v-if="v$.formModel.stageSetup.$error" class="field-error text-xs text-red-500">
          {{ v$.formModel.stageSetup.$errors[0].$message }}
        </p>
        <input type="radio" :value="stageSetupTypes.DEFAULT" v-model="formModel.stageSetup" />
        <label>Use the default stages provided by the TC Build (recommended for most)</label>
        <br />
        <input type="radio" :value="stageSetupTypes.ZILLOW_TWO_WAY_SYNC" v-model="formModel.stageSetup" />
        <label>I want to use the Zillow Stages via the 2-way sync with Zillow - more info <a class="link"
            href="https://help.followupboss.com/hc/en-us/articles/8895957403927" target="_blank">here</a></label>
        <br />
        <input type="radio" :value="stageSetupTypes.CUSTOM" v-model="formModel.stageSetup" />
        <label>I would like to opt out of using the default stages used by the TC Build. Instead, I have a requirement
          that the build be customized to use predefined stages that I will provide to Kee Technology Solutions. I
          acknowledge that I must provide names of my stages before work on the revamp can be started and <strong>I
            agree to pay a ${{ customStagesPricing }} customization fee.</strong> I acknowledge that my review is
          required before going live with the build out. (Note: We will reach out to you after your submission to obtain
          a list of stages we can use. We will reach back out after the setup is complete to review the setup with you
          before we go live with it.)</label>
      </div>
    </section>
    <!-- Project Price -->
    <section class="inline-block border p-10 bg-gray-100 text-gray-700">
      <p class="font-medium">Discount</p>
      <VNumberField :v="v$.formModel.discountAmount" :isDollarAmount="true" />
      <hr class="my-5 border-gray-400">
      <p class="text-xl font-medium">Project Price</p>
      <p class="text-lg font-medium mt-2">{{ totalDollarAmount }}</p>
      <p class="mt-2"><span class="link underline text-base font-normal"
          @click="showPriceBreakdownModal = true"><font-awesome-icon icon="fa-solid fa-info-circle" /> see price
          breakdown</span></p>
    </section>
    <PriceBreakdownModal v-if="showPriceBreakdownModal" @close="showPriceBreakdownModal = false" :pricing="pricing"
      :pricingMeta="pricingMeta" />
    <!-- Payment -->
    <section>
      <div class="mt-5">
        <p class="text-2xl font-semibold">
          Payment
        </p>
        <p class="text-lg font-semibold mt-5">
          Amount: {{ totalDollarAmount }} <span class="text-sm font-normal">(USD)</span>
        </p>
      </div>
      <div class="inline-block bg-gray-100 p-5 rounded pt-3 mt-5">
        <p class="font-semibold">Billing Information</p>
        <VTextField :v="v$.formModel.billing.firstName" placeholder="First Name" />
        <VTextField :v="v$.formModel.billing.lastName" placeholder="Last Name" />
        <VTextField :v="v$.formModel.billing.email" placeholder="Email" />
        <VTextField :v="v$.formModel.billing.addressLine1" placeholder="Address Line 1" />
        <VTextField :v="v$.formModel.billing.addressLine2" placeholder="Address Line 2" />
        <CountrySelect v-model="formModel.billing.country"
          :errorMessage="v$.formModel.billing.country.$error ? v$.formModel.billing.country.$errors[0].$message : ''" />
        <VTextField :v="v$.formModel.billing.city" placeholder="City" />
        <StateSelect v-model="formModel.billing.state"
          :errorMessage="v$.formModel.billing.state.$error ? v$.formModel.billing.state.$errors[0].$message : ''"
          :showProvinces="formModel.billing.country === 'Canada'" />
        <VTextField :v="v$.formModel.billing.zip"
          :placeholder="formModel.billing.country === 'Canada' ? 'Postal Code' : 'Zip Code'" />
        <VTextField :v="v$.formModel.billing.phone" placeholder="Phone" />
        <p class="mt-5 font-semibold">Card Information</p>
        <SquarePayment ref="squarePayment" />
      </div>
    </section>
    <button @click="submit" :disabled="!canSubmit" class="button mt-5">
      Submit and pay {{ totalDollarAmount }}
    </button>
    <p v-if="v$.$error" class="mt-2 text-red-500">
      Oops... There is an issue with the form. Check fields for errors.
    </p>
    <SubmittingModal
      v-if="isSubmitting"
      @close="(isSubmitting = false)"
      :loading="loading"
      :errorMessage="error"
      :showNextSteps="true"
    >
      <template #success>
        <div>
          <p class="text-3xl text-site-purple font-semibold">Sign up complete!</p>
          <p class="text-xl pt-5">Info was submitted successfully and payment of <strong class="font-semibold">{{totalDollarAmount}}</strong> has been processed.</p>
        </div>
      </template>
    </SubmittingModal>
  </StandardPageLayout>
</template>
<script>
import { required, formValidationMixin } from '@/mixins/formValidateMixin'
import { STAGE_SETUP_TYPES, SETUP_TYPE } from '../../../constants';
import { getPricing } from '@/pricing'
import _pick from 'lodash/pick'

export default {
  mixins: [formValidationMixin],
  data() {
    return {
      loading: false,
      error: "",
      formModel: {
        basicInfo: {
          firstName: "",
          lastName: "",
          email: "",
          phone: ""
        },
        stageSetup: "",
        discountAmount: "",
        billing: {
          firstName: "",
          lastName: "",
          email: "",
          addressLine1: "",
          addressLine2: "",
          country: "",
          city: "",
          state: "",
          zip: "",
          phone: "",
        },
      },
      isSubmitting: false,
      showPriceBreakdownModal: false,
    }
  },
  validations() {
    return {
      formModel: {
        basicInfo: {
          firstName: { required },
          lastName: { required },
          email: { required },
          phone: { required }
        },
        stageSetup: { required },
        discountAmount: {},
        billing: {
          firstName: { required },
          lastName: { required },
          email: {},
          addressLine1: { required },
          addressLine2: {},
          country: { required },
          city: { required },
          state: { required },
          zip: { required },
          phone: {}
        },
      }
    }
  },
  computed: {
    setupType() {
      return SETUP_TYPE.TC_ASSIST
    },
    pricing() {
      return getPricing(this.setupType)
    },
    pricingMeta() {
      return {
        customizeStages: this.customizeStages,
        discountAmount: this.discountAmount,
      }
    },
    basePrice() {
      return this.pricing.BASE.toLocaleString('en')
    },
    stageSetupTypes() {
      return STAGE_SETUP_TYPES
    },
    customizeStages() {
      return this.formModel.stageSetup === STAGE_SETUP_TYPES.CUSTOM
    },
    customStagesPricing() {
      return this.pricing.CUSTOM_STAGES
    },
    totalAmount() {
      let amount = this.pricing.BASE
      if (this.customizeStages) {
        amount += this.customStagesPricing
      }
      if (this.discountAmount) {
        amount -= this.discountAmount
      }
      return amount
    },
    discountAmount() {
      return Number(this.formModel.discountAmount)
    },
    discountProvided() {
      return !!this.formModel.discountAmount
    },
    totalDollarAmount() {
      return `$${this.totalAmount.toLocaleString('en')}`
    },
    canSubmit() {
      return !this.isSubmitting;
    },
  },
  methods: {
    buildNotes() {
      return _pick(this.formModel, [
        'stageSetup',
        'discountAmount'
      ])
    },
    async submit() {
      if (!(await this.v$.$validate())) return

      try {
        const notes = this.buildNotes()
        const billing = this.formModel.billing
        const { locationId, sourceId } = await this.$refs.squarePayment.tokenize()
        this.error = false
        this.isSubmitting = true
        this.loading = true

        await this.$service.manualSignUp({
          basicInfo: this.formModel.basicInfo,
          notes,
          meta: {
            setupType: this.setupType,
            customStages: this.customizeStages,
            discountProvided: this.discountProvided,
            discountAmount: this.discountAmount,
          },
          payment: {
            note: 'TC Assist Setup Payment',
            billing,
            locationId,
            sourceId,
          },
          businessAddress: this.formModel.businessAddress
        })
        this.loading = false
      } catch (e) {
        this.error = this.$service.getErrorMessage(e)
      }
    },
  }
}
</script>
<style scoped>
section {
  @apply mt-10;
}

label {
  @apply mr-2;
}

.field {
  @apply mt-2;
}

input[type="text"],
input[type="password"],
select {
  @apply border rounded py-1 px-2 w-64;
}

input[type="radio"] {
  @apply mr-2;
}

.field-block-error {
  @apply inline-block border border-red-500 p-2 rounded;
}
</style>