<template>
  <div class="bg-black text-white p-10">
    <div class="container mx-auto max-w-6xl">
      <header>
        <div class="flex justify-between items-center">
          <div class="text-center lg:text-left">
            <router-link to="/"><img :src="require(`../../../assets/logo.png`)" class="inline" style="width: 250px;" /></router-link>
          </div>
          <router-link class="bg-kts-gold text-black font-normal rounded p-3 cursor-pointer hover:opacity-80" :to="signUpRoute">Get Started</router-link>
        </div>
      </header>
      <div class="border-b border-white mt-10"></div>
      <div class="md:flex mt-20 text-center md:text-left">
        <div class="md:w-1/2 md:mr-5">
          <h1 class="text-5xl font-extrabold">Kee Technology Solutions</h1>
          <h2 class="text-3xl ml-2 text-gray-400">+</h2>
          <h1 class="text-5xl font-extrabold">{{ vendorName }}</h1>
          <p class="mr-5 text-xl mt-10">We are excited to announce a partnership with <b class="font-bold">{{ vendorName }}</b> to provide you our carefully crafted text drips so you can stay in front of your leads with automated text messaging</p>
          <router-link class="bg-kts-gold text-black font-normal rounded p-3 cursor-pointer hover:opacity-80 inline-block mt-10" :to="signUpRoute">Get Started</router-link>
        </div>
        <div class="md:ml-5 md:w-1/2 mt-10 md:mt-0">
          <Video :videoId="videoId" />
        </div>
      </div>
      <div class="border-b border-white mt-20"></div>
      <h3 class="text-5xl text-center font-bold mt-20">Pair our <span class="text-kts-gold">text drips 💬</span><br>w/ {{ vendorName }}!</h3>
      <div class="mt-20 bg-white rounded-xl text-black p-10">
        <div class="md:text-center">
          <p class="font-normal text-xl">Make use of our carefully crafted text drips that help start meaningful conversations</p>
          <p class="mt-5">Our text messages are <b>open ended</b> and <b>reply based</b> with proven results<br>built upon the scripts and communication methods of <b>Phil Jones</b></p>
          <p class="mt-5">Once you are setup using {{ vendorName }}, we can provide you our text drips (noted below)</p>
        </div>
        <div class="md:flex mt-10">
          <div class="md:w-1/2 md:mr-10">
            <img :src="require(`../../../assets/images/text-drips.png`)" class="rounded-xl" />
          </div>
          <div class="md:w-1/2 md:ml-10">
            <ul class="text-sm bg-gray-100 font-normal rounded-xl border border-gray-200">
              <li v-for="smsDrip, idx in defaultSmsDrips" :key="idx" class="p-2" :class="{'border-b border-gray-200': idx + 1 < defaultSmsDrips.length}">{{ smsDrip }}</li>
            </ul>
            <h2 class="mt-10 mb-3 text-lg">Nurture Drips</h2>
            <ul class="text-sm bg-gray-100 font-normal rounded-xl border border-gray-200">
              <li v-for="smsDrip, idx in nurtureSmsDrips" :key="idx" class="p-2" :class="{'border-b border-gray-200': idx + 1 < nurtureSmsDrips.length}">{{ smsDrip }}</li>
            </ul>
          </div>
        </div>
        <div class="text-center mt-10 flex flex-col items-center">
          <p class="text-lg font-semibold">Price structure for our text drips:</p>
          <p class="text-2xl mt-5">Price: ${{ pricing.BASE }}</p>
          <p class="mt-5">(one time payment)</p>
        </div>
        <p class="text-center mt-10">
          <router-link :to="signUpRoute">
            <button class="bg-kts-gold rounded py-3 px-5 inline-block font-normal hover:opacity-80">Get Started</button>
          </router-link>
        </p>
      </div>
      <div class="text-center mt-20">&copy; {{ $copyrightDate }} by Kee Technology Solutions LLC</div>
    </div>
  </div>
</template>
<script>
import { SETUP_TYPE, SMS_VENDOR } from '../../../constants'
import { getPricing } from '@/pricing'

export default {
  props: ['vendor'],
  computed: {
    pricing() {
      return getPricing(SETUP_TYPE.FUB_AGENT_SMS)
    },
    defaultSmsDrips() {
      return [
        'New Buyer Lead - 20 texts for one year',
        'New Home Valuation - 16 texts for one year',
        'New Seller - 16 texts for one year',
        'New zBuyer Seller - 16 texts for one year',
        'New Renter - 19 texts for one year',
        'New Open House - 21 texts for one year',
        'New Construction Lead - 18 months (approx. 22 texts)',
        'New Zillow, Realtor, Trulia, Redfin - 30 texts for two years',
        'New Branded Website Lead - 11 texts for 6 months',
        'Stale - 15 texts for one year',
        'Back to Website - 9 texts for 4 months',
        'Homebot/Home Equity Watch Engaged - 12 texts for 6 months',
        'RealScout Users - 6 automations based on behavior tags',
        'Fello Users - 9 automations based on behavior tags'
      ]
    },
    nurtureSmsDrips() {
      return [
        'Nurture Buyer - 23 texts for 3.5 years',
        'Nurture Seller - 23 texts for  3.5 years',
        'Nurture Buyer + Seller - 31 texts for just under 5 years',
        'Nurture Luxury Buyer - 20 texts over 3 years',
        'Nurture Luxury Seller - 17 texts over 2.5 years',
        'Nurture Luxury Buyer + Seller - 37 texts over 3 years',
        'Nurture Expired - 17 texts for just under 1 year',
        'Nurture FSBO - 15 texts for just under 1 year',
        'Renter to Future Buyer - 14 texts for 2 years',
        'Nurture Probate - 17 texts for almost 2 years',
        'Nurture New Construction - 17 months (approx. 17 texts)'
      ]
    },
    vendorName() {
      switch (this.vendor) {
        case SMS_VENDOR.LEADNGAGE: return 'Leadngage'
        case SMS_VENDOR.STREET_TEXT: return 'StreetText'
        case SMS_VENDOR.TEXTING_BETTY:
        default: return 'Texting Betty'
      }
    },
    videoId() {
      switch (this.vendor) {
        case SMS_VENDOR.LEADNGAGE: return 'HieqjCqKNdI'
        case SMS_VENDOR.STREET_TEXT: return 'rH0st25WjNE'
        case SMS_VENDOR.TEXTING_BETTY:
        default: return 'r7L8olKhOmg'
      }
    },
    signUpRoute() {
      return `/texting-sign-up?vendor=${this.vendor}`
    }
  },
}
</script>