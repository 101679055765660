<template>
  <StandardPageLayout title="Free Follow Up Boss Audit" :loading="isSubmitting" :success="success" :error="errorMessage">
    <p class="text-lg mt-10 text-center">Our complimentary FUB audit features a detailed 10-15 minute video analysis of your account. In this video, we will highlight the strengths of your CRM and identify areas with potential for improvement.</p>
    <div class="mt-10 bg-gray-100 p-10 rounded-xl border border-gray-200 max-w-md mx-auto">
      <form @submit.prevent="submit">
        <p class="font-semibold">Your Info</p>
        <VTextField :v="v$.formModel.firstName" placeholder="First Name" size="full" />
        <VTextField :v="v$.formModel.lastName" placeholder="Last Name" size="full" />
        <VTextField :v="v$.formModel.email" placeholder="Email" size="full" />
        <VTextField :v="v$.formModel.phone" placeholder="Phone" size="full" />
        <p class="font-semibold mt-10">Account Credentials <span class="font-normal">(Owner Account)</span></p>
        <p class="text-sm text-gray-500">For security, we store passwords using encryption.</p>
        <VTextField :v="v$.formModel.fubUsername" placeholder="Username" autocomplete="off" size="full" />
        <VTextField :v="v$.formModel.fubPassword" placeholder="Password" autocomplete="off" size="full" />
        <p class="text-sm mt-10">By clicking "Submit," I authorize Kee Technology Solutions to log in and perform an account audit.</p>
        <button type="submit" class="button w-full">Submit</button>
      </form>
    </div>
    <template #success>
      <div class="text-center mt-10">
        <p class="text-lg">Thanks for signing up for our FUB audit! A confirmation email has been sent your way with audit details and next steps.</p>
        <button class="button mt-5" @click="$router.replace('/')">Done</button>
      </div>
    </template>
  </StandardPageLayout>
</template>
<script>
import { required, formValidationMixin } from '@/mixins/formValidateMixin'
export default {
  mixins: [formValidationMixin],
  data() {
    return {
      isSubmitting: false,
      success: false,
      errorMessage: "",
      agreeToConditions: "",
      formModel: {
        firstName: "",
        lastName: "",
        email: "",
        phone: "",
        fubUsername: "",
        fubPassword: ""
      }
    }
  },
  validations() {
    return {
      formModel: {
        firstName: { required },
        lastName: { required },
        email: { required },
        phone: { required },
        fubUsername: { required },
        fubPassword: { required }
      }
    }
  },
  methods: {
    async submit() {
      if (!(await this.v$.$validate())) return

      this.isSubmitting = true
      this.errorMessage = ""

      try {
        await this.$service.postAuditInquiry(this.formModel)
        this.success = true
      } catch (e) {
        this.errorMessage = this.$service.getErrorMessage(e)
      }
    }
  }
}
</script>