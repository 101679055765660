<template>
  <div class="mt-2" v-if="affiliates.length > 0">
    <SelectWrapper>
      <select
        :value="value"
        @input="$emit('input', $event.target.value)"
        :class="{ 'border-red-500': errorMessage, 'text-gray-400': value === '' }"
        class="border rounded p-1 w-64"
      >
        <option value="">None Selected</option>
        <option v-for="affiliate in affiliates" :key="affiliate">{{ affiliate }}</option>
      </select>
    </SelectWrapper>
    <p v-if="errorMessage" class="text-xs text-red-500">{{ errorMessage }}</p>
  </div>
</template>
<script>
import SelectWrapper from './SelectWrapper'
import _sortBy from 'lodash/sortBy'
export default {
  components: { SelectWrapper },
  data: () => ({
    affiliates: []
  }),
  async created() {
    try {
      const affiliates = await this.$service.getAffiliateSlugs()
      this.affiliates = _sortBy(affiliates, 'name').map(({ name }) => name)
    } catch {
      console.error('Could not retrieve affiliates')
    }
  },
  props: {
    errorMessage: {
      type: String,
      required: false,
      default: ""
    },
    value: {
      type: String,
      required: true,
    },
  },
}
</script>