import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faCaretDown,
  faCaretUp,
  faTimes,
  faPlusCircle,
  faClock,
  faSpinner,
  faMessage,
  faPaperPlane,
  faMinus,
  faClipboard,
  faInfoCircle,
  faBars,
  faArrowLeft,
  faArrowRight,
  faChevronUp,
  faChevronDown,
  faTrash
} from '@fortawesome/free-solid-svg-icons'

library.add(
  faCaretDown,
  faCaretUp,
  faTimes,
  faPlusCircle,
  faClock,
  faSpinner,
  faMessage,
  faPaperPlane,
  faMinus, 
  faClipboard,
  faInfoCircle,
  faBars,
  faArrowLeft,
  faArrowRight,
  faChevronUp,
  faChevronDown,
  faTrash
)